import React, { useEffect, useState } from 'react'
import { visit, getWindowSize } from './utils'

const Slide = ({mobilebanner, desktopBanner, slug}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobileSize, setIsMobileSize] = useState(windowSize.innerWidth >= 400 ? false : true)

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setIsMobileSize(windowSize.innerWidth >= 400 ? false : true)
  }, [windowSize])

  return (
    <div onClick={() => visit(slug)}>
      <img src={isMobileSize ? mobilebanner : desktopBanner} />
    </div>
  )
}

export default Slide
