const pluralize = (count, singular, plural) => {
  let word = singular

  if (count && count > 1) word = plural

  return word
}

const isConnected = () => {
  let name = 'customer.id='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return true
    }
  }

  return false
}

const uuidV4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const queryString = (values, baseKey = '') => {
  let result = []
  for (const key_or_index in values) {
    const key = baseKey.length === 0 ? key_or_index : baseKey
    const value = values[key_or_index]
    if (Array.isArray(value)) {
      result = result.concat(queryString(value, `${key}[]`))
    } else {
      result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }
  return result.join('&')
}

const baseUrl = `${window.location.protocol}//${window.location.host}`

const buildURI = (path, params) => {
  const url = new URL(path, baseUrl)
  url.search = queryString(params)
  return url.toString()
}

const historyPush = (url, data = null) => {
  history.pushState(data, '', url)
}

const productTrackingParams = (product, { quantity = 1, index = null, list_id = null, list_name = null } = {}) => {
  return {
    id: product.id,
    name: product.name,
    price: product.price,
    category: product?.primary_theme?.name,
    quantity: quantity,
    url: `${baseUrl}/coffrets-cadeaux/${product.slug}`,
    position: index,
    brand: product.brand.name,
    list_id: list_id,
    list_name: list_name
  }
}

const productsTrackingParams = (products, { list_id = null, list_name = null } = {}) => {
  return {
    items: products.map((product, index) => {
      return productTrackingParams(product, { index, list_id, list_name })
    })
  }
}

const showTrackingParams = (show, { quantity = 1, index = null, list_id = null, list_name = null } = {}) => {
  return {
    id: show.id,
    name: show.name,
    venue: show?.venue?.name,
    category: show.show_type,
    quantity: quantity,
    url: `${baseUrl}/evenements/${show.slug}`,
    position: index,
    list_id: list_id,
    list_name: list_name
  }
}

const showsTrackingParams = (shows, { list_id = null, list_name = null } = {}) => {
  return {
    items: shows.map((show, index) => {
      return showTrackingParams(show, { index, list_id, list_name })
    })
  }
}

const visit = (url) => {
  window.location.href = url
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export {
  baseUrl,
  buildURI,
  historyPush,
  isConnected,
  pluralize,
  queryString,
  uuidV4,
  productsTrackingParams,
  productTrackingParams,
  showsTrackingParams,
  showTrackingParams,
  visit,
  getWindowSize
}
