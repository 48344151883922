import React from 'react'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import Slide from './slide'
import moment from 'moment/moment'

import './slider.scss'

import dbMM01 from '../../assets/images/static_pages/home/db_MM01.png'
import dbTTAFF01 from '../../assets/images/static_pages/home/db_TTAFF01.png'

import mbMM01 from '../../assets/images/static_pages/home/mb_MM01.png'
import mbTTAFF01 from '../../assets/images/static_pages/home/mb_TTAFF01.png'

import ny01_desk from '../../assets/images/static_pages/home/NY01_desktop.png'
import ny01_mobile from '../../assets/images/static_pages/home/NY01_mobile.png'
import TSP01_desktop from '../../assets/images/static_pages/home/TSP01_desktop.png'
import TSP01_mobile from '../../assets/images/static_pages/home/TSP01_mobile.png'
import TTAFF01_desktop from '../../assets/images/static_pages/home/TTAFF01_desktop.png'
import TTAFF01_mobile from '../../assets/images/static_pages/home/TTAFF01_mobile.png'
import HUM01_desktop from '../../assets/images/static_pages/home/HUM01_desktop.png'
import HUM01_mobile from '../../assets/images/static_pages/home/HUM01_mobile.png'

import STVAL02_desktop from '../../assets/images/static_pages/home/STVAL02_desktop.png'
import STVAL02_mobile from '../../assets/images/static_pages/home/STVAL02_mobile.png'
import STVAL03_desktop from '../../assets/images/static_pages/home/STVAL03_desktop.png'
import STVAL03_mobile from '../../assets/images/static_pages/home/STVAL03_mobile.png'
import STVAL04_desktop from '../../assets/images/static_pages/home/STVAL04_desktop.png'
import STVAL04_mobile from '../../assets/images/static_pages/home/STVAL04_mobile.png'

const Slider = () => {
  const isProduction = window.App.env === 'production'
  const day = moment()
  const slides = []

  const MM01 = () => {
    return (
      <Slide
        mobilebanner={mbMM01}
        desktopBanner={dbMM01}
        slug='coffrets-cadeaux/coffrets-cadeaux-insolites?utm_source=slider_mm02'
      />
    )
  }

  // const TSP01 = () => {
  //   return (
  //     <Slide
  //       mobilebanner={mbTSP01}
  //       desktopBanner={dbTSP01}
  //       slug='coffrets-cadeaux/coffret-spectacle-premium?utm_source=slider_tsp01'
  //     />
  //   )
  // }

  // const TTAFF01 = () => {
  //   return (
  //     <Slide
  //       mobilebanner={mbTTAFF01}
  //       desktopBanner={dbTTAFF01}
  //       slug='coffrets-cadeaux/coffret-tetes-affiches?utm_source=sliderttaff'
  //     />
  //   )
  // }

  const NY01 = () => {
    return (
      <Slide
        mobilebanner={ny01_mobile}
        desktopBanner={ny01_desk}
        slug='coffrets-cadeaux/coffret-humour?utm_source=slider_ny01'
      />
    )
  }

  const HUM01 = () => {
    return (
      <Slide
        mobilebanner={HUM01_mobile}
        desktopBanner={HUM01_desktop}
        slug='coffrets-cadeaux/coffret-humour?offer_types=b9b46f4d-418c-4d20-9ed6-305550db07cb&utm_source=sliderHUM01'
      />
    )
  }
  
  const TSP01 = () => {
    return (
      <Slide
        mobilebanner={TSP01_mobile}
        desktopBanner={TSP01_desktop}
        slug='coffrets-cadeaux/coffret-spectacle-premium?utm_source=slider_tsp01'
      />
    )
  }
  
  const TTAFF01 = () => {
    return (
      <Slide
        mobilebanner={TTAFF01_mobile}
        desktopBanner={TTAFF01_desktop}
        slug='coffrets-cadeaux/coffret-tetes-affiches?utm_source=slider_ttaff01'
      />
    )
  }

  const STVAL02 = () => {
    return (
      <Slide
        mobilebanner={STVAL02_mobile}
        desktopBanner={STVAL02_desktop}
        slug='coffrets-cadeaux/saint-valentin?utm_source=sliderstval02'
      />
    )
  }

  const STVAL03 = () => {
    return (
      <Slide
        mobilebanner={STVAL03_mobile}
        desktopBanner={STVAL03_desktop}
        slug='coffrets-cadeaux/saint-valentin?utm_source=sliderstval03'
      />
    )
  }

  const STVAL04 = () => {
    return (
      <Slide
        mobilebanner={STVAL04_mobile}
        desktopBanner={STVAL04_desktop}
        slug='coffrets-cadeaux/saint-valentin?utm_source=sliderstval04'
      />
    )
  }


  if(day.isBetween('2024-01-01', '2024-01-15') && isProduction) {
    slides.push(NY01())
    slides.push(TSP01())
    slides.push(TTAFF01())
  } else if(day.isBetween('2024-01-16', '2024-01-28') && isProduction) {
    slides.push(TSP01())
    slides.push(TTAFF01())
    slides.push(HUM01())
  } else if(day.isBetween('2024-02-01', '2024-02-08', undefined, '[]') && isProduction) {
    slides.push(STVAL02())
    slides.push(TSP01())
    slides.push(TTAFF01())
  } else if(day.isBetween('2024-02-09', '2024-02-13', undefined, '[]') && isProduction) {
    slides.push(STVAL03())
    slides.push(TSP01())
    slides.push(TTAFF01())
  } else if(day.isBetween('2024-02-14', '2024-02-15', undefined, '[]') && isProduction) {
    slides.push(STVAL04())
    slides.push(TSP01())
    slides.push(TTAFF01())
  } else {
    slides.push(TSP01())
    slides.push(HUM01())
    slides.push(TTAFF01())
  }



  return (
    <div className="--citc-slider">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        emulateTouch={true}
        interval={4000}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
      >
        {slides.map((slide, index) => (
          <React.Fragment key={index}>
            {slide}
          </React.Fragment>
        ))}
      </Carousel>

    </div>
  )
}

export default Slider
