import React from 'react'
import { visit } from './utils'

import './headerTile.scss'

const HeaderTile = ({ data }) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`

  return (
    <div className={`--citc-header-tile`}>
      {
        data.map((content, key) => (
          <div key={key} className="content" onClick={() => visit(`${baseUrl, content.path}`)}>
            <img className={content.type} src={baseUrl + content.image_path} alt={content.title} />
            <p>{content.title}</p>

            {typeof content.price !== 'undefined' &&
              <div className="price">
                <p>{content.price},00€</p>
              </div>
            }
          </div>
        ))
      }
    </div>
  )
}

export default HeaderTile
