import React, { useRef } from 'react'
import { baseUrl, productsTrackingParams, productTrackingParams, visit } from './utils'
import CustomButton from './CustomButton'
import Tag from './tag'

import './topProducts.scss'
import './product.scss'

const TopProducts = ({ data }) => {
  const scrl = useRef(null)
  const listId = 'top_products'
  const listName = 'Coffrets du moment'

  const handleClickSeeAllTopProducts = () => {
    visit(`${baseUrl}/coffrets-cadeaux?all=true`)
  }

  const handleClickSeeProduct = (dataSlug) => {
    visit(`${baseUrl}/coffrets-cadeaux/${dataSlug}`)
  }

  const scroll = (scrollOffset) => {
    scrl.current.scrollLeft += scrollOffset
  }

  const getImageRenderer = (dataSlug) => {
    return `${baseUrl}/products/${dataSlug}/representations/default/thumbnail.png`
  }

  return (
    <div className="top-products-container" data-controller="tracking" data-tracking-auto-track-type-value="product"
         data-tracking-auto-track-name-value="view_item_list"
         data-tracking-auto-track-params-value={JSON.stringify(productsTrackingParams(data, { list_id: listId, list_name: listName }))}>
      <div className="top-products-head">
        <h1>Nos coffrets du moment</h1>
        <div className="top-products-nav">
          <CustomButton text="Voir tout" style="underline" size="sm" onClick={handleClickSeeAllTopProducts} />
          <span className="icon-arrow-left top-products-rounded-icons" onClick={() => scroll(-800)}></span>
          <span className="icon-arrow-right top-products-rounded-icons" onClick={() => scroll(800)}></span>
        </div>
      </div>
      <div ref={scrl} className="top-products-list">
        {
          data.map((content, key) => (
            <div key={key} className="product-container" data-controller="tracking" data-tracking-track-type-value="product"
                 data-tracking-track-name-value="select_item"
                 data-tracking-track-params-value={JSON.stringify(productTrackingParams(content, { list_id: listId, list_name: listName }))}>
              <div className="product-image" data-action="click->tracking#selectItem" onClick={() => handleClickSeeProduct(content.slug)}>
                <img src={getImageRenderer(content.slug)} alt="logo" />
              </div>
              <div className="product-name" data-action="click->tracking#selectItem" onClick={() => handleClickSeeProduct(content.slug)}>
                <p>{content.name}</p>
              </div>
              <div className="product-price-tags" data-action="click->tracking#selectItem" onClick={() => handleClickSeeProduct(content.slug)}>
                <div className="tags">
                  {content.shippable && <Tag text="Physique" color="physic" info="Livré en 3 à 5 jours ouvrés." />}
                </div>
                <p>{content.price},00€</p>
              </div>
            </div>
          ))
        }
      </div>
      <div className="top-products-cta">
        <CustomButton text="Voir tous nos coffrets" style="broke-corner" onClick={handleClickSeeAllTopProducts} />
      </div>
    </div>
  )
}

export default TopProducts
