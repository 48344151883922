import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Api from './api';
import { baseUrl, buildURI, historyPush } from './utils';
import CheckboxesListButton from './CheckboxesListButton';
import CustomButton from './CustomButton';
import CustomInput from './CustomInput';
import RadioListButton from './radioListButton';
import ToggableSection from './toggableSection';
import './filters.scss';
import omitEmpty from 'omit-empty';

const Filters = ({ allCities, handleClickClose, onParamsChange, onReset, reset }) => {
  const [state, setState] = useState('initial');
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cities, setCities] = useState([]);
  const [showTypes, setShowTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [offerTypes, setOfferTypes] = useState([]);
  const [ageFrom, setAgeFrom] = useState(null);
  const [ageTo, setAgeTo] = useState(null);
  const [disabledAccess, setDisabledAccess] = useState([]);
  const [magneticLoop, setMagneticLoop] = useState([]);
  const [visuallyImpairedAccess, setVisuallyImpairedAccess] = useState([]);
  const [zones, setZones] = useState([]);

  const [aggregates, setAggregates] = useState({
    cities: [],
    showTypes: [],
    categories: [],
    offerTypes: [],
    ages: [],
    disabledAccess: [{ key: 'true', label: 'Mobilité réduite' }],
    magneticLoop: [{ key: 'true', label: 'Boucle magnétique' }],
    visuallyImpairedAccess: [{ key: 'true', label: 'Mal et non-voyants' }],
  });

  const startCities = [
    { key: "Paris", label: "Paris" },
    { key: "Marseille", label: "Marseille" },
    { key: "Lyon", label: "Lyon" },
    { key: "Toulouse", label: "Toulouse" },
    { key: "Nantes", label: "Nantes" },
    { key: "Nice", label: "Nice" },
    { key: "Montpellier", label: "Montpellier" },
    { key: "Strasbourg", label: "Strasbourg" }
  ]

  cities.forEach(elem => {
    if(!startCities.some(item => item.key === elem)) startCities.push({key: elem, label: elem})
  })

  const setDates = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const setAges = (keys) => {
    const [from, to] = keys[0] ? keys[0].split('_') : ['', ''];
    setAgeFrom(from.length > 0 ? from : null);
    setAgeTo(to.length > 0 ? to : null);
  };

  const computeLabelAgesRanges = (age) => {
    if (age.from && age.to) {
      return age.from + ' ans à ' + age.to + ' ans';
    } else if (!age.from && age.to) {
      return 'Jusqu\'à ' + age.to + ' ans';
    } else if (age.from && !age.to) {
      return 'À partir de ' + age.from + ' ans';
    }
  };

  const getAggregates = (params = {}) => {
    Api.shows.aggregates(params).then(json => {
      setAggregates({
        cities: json.data.cities.map(city => ({ key: city, label: city })),
        showTypes: json.data.show_types.map(showType => ({ key: showType.key, label: showType.name })),
        categories: json.data.categories.map(category => ({ key: category.key, label: category.name })),
        offerTypes: json.data.offer_types.map(offerType => ({ key: offerType.key, label: offerType.name })),
        ages: json.data.ages.map(age => ({ key: `${age.from || ''}_${age.to || ''}`, label: computeLabelAgesRanges(age) })),
        disabledAccess: [{ key: 'true', label: 'Mobilité réduite' }],
        magneticLoop: [{ key: 'true', label: 'Boucle magnétique' }],
        visuallyImpairedAccess: [{ key: 'true', label: 'Mal et non-voyants' }],
      });
    });
  };

  const resetFilters = () => {
    setSearch('');
    setDates(null, null);
    setCities([]);
    setShowTypes([]);
    setCategories([]);
    setOfferTypes([]);
    setAges(['', '']);
    setDisabledAccess([]);
    setMagneticLoop([]);
    setVisuallyImpairedAccess([]);
    setZones([]);
  };

  useEffect(() => {
    if (!reset) return;
    resetFilters();
  }, [reset]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setSearch(params.get('search') || '');
    setStartDate(params.get('from_date') ? moment(params.get('from_date')) : null);
    setEndDate(params.get('to_date') ? moment(params.get('to_date')) : null);
    setCities(params.getAll('cities[]'));
    setShowTypes(params.getAll('show_types[]'));
    setCategories(params.getAll('categories[]'));
    setOfferTypes(params.getAll('offer_types[]'));
    setAgeFrom(params.get('age_from') || null);
    setAgeTo(params.get('age_to') || null);
    setDisabledAccess(params.get('disabled_access') ? [params.get('disabled_access')] : []);
    setMagneticLoop(params.get('magnetic_loop') ? [params.get('magnetic_loop')] : []);
    setVisuallyImpairedAccess(params.get('visually_impaired_access') ? [params.get('visually_impaired_access')] : []);
    setZones(params.getAll('zones[]') || []);
    setState('mounted');
  }, []);

  useEffect(() => {
    searchShows();
  }, [startDate, endDate, cities, showTypes, categories, offerTypes, ageFrom, ageTo, disabledAccess, magneticLoop, visuallyImpairedAccess]);

  const searchShows = () => {
    if (state === 'initial') return;

    const params = omitEmpty({
      search,
      from_date: startDate?.format('YYYY-MM-DD'),
      to_date: endDate?.format('YYYY-MM-DD'),
      cities,
      show_types: showTypes,
      categories,
      offer_types: offerTypes,
      age_from: ageFrom,
      age_to: ageTo,
      disabled_access: disabledAccess[0],
      magnetic_loop: magneticLoop[0],
      visually_impaired_access: visuallyImpairedAccess[0],
      zones,
    });

    if (state === 'mounted') {
      setState('initialized');
    } else {
      historyPush(buildURI('evenements', params));
    }

    onParamsChange(params);

    getAggregates(params);
  }

  const baseUrl = `${window.location.protocol}//${window.location.host}`

  const displayLenght = (title, array) => {
    if (array.length < 1) {
      return `${title}`
    } else {
      return `${title} (${array.length})`
    }
  }

  const enterKeyPressed = (event) => {
    if (event.keyCode !== 13) return
    searchShows()
  }

  return (
    <div className="--citc-filters">
      <div className="hd-filters">
        <div className="header">
          <h1>Filtres</h1>
          <CustomButton text="Fermer" iconRight="&#xea57;" style="underline" onClick={handleClickClose} size="xl" />
        </div>
        <div className="mobile-section">
          <CustomButton text="Effacer" style="no-background" color="red" fit={true} onClick={() => onReset(true)} />
          <CustomButton text="Appliquer" color="black" fit={true} onClick={handleClickClose} />
        </div>
        <div className="section">
          <h2>Évènements</h2>
          <div class="section--content">
            <CustomInput icon="&#xeb65;" placeHolder="Quoi ?" style={'default-input'} initialValue={search} onChange={setSearch} onPress={enterKeyPressed} 
                          autoCompleteUrl={(query) => `${baseUrl}/api/search/shows?q=${query}`}
                          onData={(res) => res.reduce((previous, current) => {
                            let venueInfos = ''

                            if (current.venue) {
                              if (current.venue.city) venueInfos += current.venue.city
                              if (current.venue.zipcode) venueInfos += ` (${current.venue.zipcode})`
                            }

                            previous[current.id] = { label: current.name, subLabel: venueInfos, extra: { url: current.public_url } }
                            return previous
                          }, {})} viewAllUrl={(query) => `${baseUrl}/evenements?search=${query}`} />
            <CustomButton color="primary" size="xl" style="broke-corner" tagName="button" onClick={searchShows} iconRight="&#xead3;" />
          </div>

        </div>
        <ToggableSection title={displayLenght("Localisation", cities)}>
          <CheckboxesListButton selectable={true} title="Villes" placeholder="Où ?" icon="&#xea12;"
            dataList={startCities} options={allCities} onChange={setCities} initialValues={cities} reset={reset} />
        </ToggableSection>
        <ToggableSection title={displayLenght("Thématiques", showTypes)}>
          <CheckboxesListButton icon="&#xea12;" possibilityCheckAll={true} showedInput={false} dataList={aggregates.showTypes}
            onChange={setShowTypes} initialValues={showTypes} reset={reset} />
        </ToggableSection>
        <ToggableSection title="Dates">
          <CustomInput icon="&#xec8f;" type="date" alwaysShow={true} placeholder="Quand ?" onChange={setDates}
            initialEndDate={endDate} initialStartDate={startDate} reset={reset} />
        </ToggableSection>
        <ToggableSection title={displayLenght("Genres", categories)}>
          <CheckboxesListButton icon="&#xea12;" possibilityCheckAll={false} showedInput={false} dataList={aggregates.categories}
            onChange={setCategories} initialValues={categories} reset={reset} />
        </ToggableSection>
        <ToggableSection title={displayLenght("Offres", offerTypes)}>
          <CheckboxesListButton icon="&#xea12;" possibilityCheckAll={false} showedInput={false} dataList={aggregates.offerTypes}
            onChange={setOfferTypes} initialValues={offerTypes} reset={reset} />
        </ToggableSection>
        <ToggableSection title="Âge">
          <RadioListButton icon="&#xea12;" possibilityCheckAll={false} showedInput={false} dataList={aggregates.ages}
            onChange={setAges} initialValues={[`${ageFrom || ''}_${ageTo || ''}`]} reset={reset} />
        </ToggableSection>
        <ToggableSection title="Accessibilité">
          <CheckboxesListButton icon="&#xea12;" possibilityCheckAll={false} showedInput={false} dataList={aggregates.disabledAccess}
            onChange={setDisabledAccess} initialValues={disabledAccess} reset={reset} />
          <CheckboxesListButton icon="&#xea12;" showedInput={false} dataList={aggregates.magneticLoop}
            onChange={setMagneticLoop} initialValues={magneticLoop} reset={reset} />
          <CheckboxesListButton icon="&#xea12;" showedInput={false} dataList={aggregates.visuallyImpairedAccess}
            onChange={setVisuallyImpairedAccess} initialValues={visuallyImpairedAccess} reset={reset} />
        </ToggableSection>
        <div className="section desktop-section">
          <CustomButton text="Effacer tous les filtres" iconLeft="&#xeb73;" style="no-background" color="red" fit={true} onClick={() => onReset(true)} />
        </div>
	<div className="mobile-section"></div>
      </div>
    </div>
  );
};

export default Filters;
