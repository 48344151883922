import React, { useEffect, useState } from 'react'

import citcLogo from '../../assets/images/logos/culturinthecity-logo-rgb.png'
import citcLogoChristmas from '../../assets/images/logos/culturinthecity-logo-christmas.png'
import CustomButton from './CustomButton'
import { isConnected, visit } from './utils'

import './header.scss'
import HeaderTabs from './headerTabs'
import HeaderTabsMobile from './headerTabsMobile'
import SearchBar from './SearchBar'

const Header = ({ showTypes, cities, topCities, hasSearchbar }) => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false)
  const [data, setData] = useState([])
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  const cartCount = window.App.cartCount < 99 && window.App.cartCount
  const mobileHeaderClassNames = ['mobile']

  if (!toggleMobileMenu) mobileHeaderClassNames.push('hidden')

  useEffect(() => {
    fetch(`${baseUrl}/api/navigation`)
      .then((response) => response.json())
      .then((json) => setData(json))
  }, [])

  const _activePath = (path) => {
    let active

    if (window.location.pathname === path) {
      active = true
    } else {
      active = window.location.pathname.startsWith(path) && data.find(content => window.location.pathname === content.path) === undefined
    }

    return active
  }

  const isChristmasTime = () => {
    const currentDate = new Date();
    const startDate = new Date(2023, 10, 7);
    const endDate = new Date(2023, 11, 26);
    if (currentDate >= startDate && currentDate <= endDate) {
      return citcLogoChristmas;
    }
    else {
      return citcLogo
    }
  }

  return (
    <>
      <div className="--citc-header">
        <div className="burger-menu" onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>
          <span className="icon">&#xec48;</span>
        </div>
        <div className="left">
          <img onClick={() => visit(baseUrl)} src={isChristmasTime()} />
          <ul>
            {data.map((content, key) => (
              <li key={key}>
                <HeaderTabs tab={content} active={_activePath(content.path)} />
              </li>
            ))}
          </ul>
        </div>

        <div className="right">
          <ul>
            <li>
              <CustomButton tagName="button" text="Enregistrer mon produit" style="broke-corner" size="m" color="primary"
                            onClick={() => visit(`${baseUrl}/redeem`)} />
            </li>
            <li className="flex-column-tab">
              <CustomButton tagName="button" text="Compte" style="underline" size="m" color="black"
                            onClick={() => visit(`${isConnected() ? '/account' : '/account/signin'}`)} />
            </li>
            <li className="flex-column-tab">
              <HeaderTabs tab={{
                prefix: <span className="icon">&#xeaeb;</span>,
                suffix: window.App.cartCount > 0 && <span className="suffix count">{cartCount}</span>,
                path: '/cart'
              }} active={_activePath('/cart')} />
              <a href="/cart">Panier</a>
            </li>
          </ul>
        </div>

        <div className={mobileHeaderClassNames.join(' ')}>
          <div className="title">
            <h2>Menu</h2>
            <CustomButton tagName="button" text="Fermer" style="underline" iconRight="&#xea57;" color="primary" onClick={() => setToggleMobileMenu(false)} />
          </div>
          <ul>
            {data.map((content, key) => (
              <li key={key}>
                <HeaderTabsMobile tab={content} active={_activePath(content.path)} />
              </li>
            ))}
            <li>
              <HeaderTabsMobile tab={{
                prefix: <span className="icon">&#xeaeb;</span>,
                title: 'Mon panier',
                suffix: window.App.cartCount > 0 && <span className="suffix count">{cartCount}</span>,
                path: '/cart'
              }} active={_activePath('/cart')} />
            </li>
            <li>
              <CustomButton tagName="button" text="Mon compte" style="underline" size="m" color="black"
                            onClick={() => visit(`${baseUrl}/account`)} />
            </li>
            <li>
              <CustomButton tagName="button" text="Enregistrer mon produit" style="broke-corner" size="m" color="primary"
                            onClick={() => visit(`${baseUrl}/redeem`)} />
            </li>
          </ul>
        </div>
      </div>
      {
        !toggleMobileMenu && hasSearchbar && <SearchBar showTypes={showTypes} cities={cities} topCities={topCities} />
      }
    </>
  )
}

export default Header
