import React, { useEffect, useState } from 'react'
import { uuidV4 } from './utils'

import './CustomCheckboxesList.scss'

const CustomCheckboxesList = ({ options, onChange, possibilityCheckAll, initialValues = [], type = 'checkbox' }) => {
  // State with list of all checked item
  const [checkableList, setCheckableList] = useState({})
  const [allSelected, setAllSelected] = useState(false)
  const [inputCheckAllId, setInputCheckAllId] = useState(null)
  const isRadio = type === 'radio'
  const isCheckAllPossible = possibilityCheckAll && !isRadio

  // Add/Remove checked item from list
  const doCheck = (event) => {
    if (isRadio) {
      checkedItems()
        .forEach(([key, _value]) => {
          checkableList[key].checked = false
        })
    }

    checkableList[event.target.value].checked = event.target.checked
    setCheckableList({ ...checkableList })
    if (isCheckAllPossible) setAllSelected(items().filter(([_key, { checked }]) => !checked).length === 0)
    onChange(checkedItems().map(([key, _data]) => key))
  }

  const doCheckAll = (event) => {
    items()
      .forEach(([key, _value]) => {
        checkableList[key].checked = event.target.checked
      })

    setCheckableList({ ...checkableList })
    setAllSelected(event.target.checked)
    onChange(checkedItems().map(([key, _data]) => key))
  }

  const items = () => Object.entries(checkableList)
  const checkedItems = () => items().filter(([_key, { checked }]) => checked)

  useEffect(() => {
    setInputCheckAllId(`checkboxes-check-all-${uuidV4()}`)
  }, [])

  useEffect(() => {
    Object.entries(options)
      .forEach(([key, value]) => {
        checkableList[value?.key || key] = { name: value.label, checked: initialValues.includes(value?.key || key), id: `checkboxes-item-${uuidV4()}` }
      })

    setCheckableList({ ...checkableList })
  }, [options])

  useEffect(() => {
    initialValues.forEach(value => {
      if (checkableList[value]) checkableList[value].checked = true
    })

    setCheckableList({ ...checkableList })
  }, [initialValues])

  return (
    <div className="--citc-custom-checkboxes-list">
      <div className="list-container-checkbox">
        {Object.entries(checkableList)
          .map(([key, data]) => (
            <div className={`list-items-checkbox${isRadio ? ' radio' : ''}`} key={data.id}>
              <input value={key} type={type} id={data.id} checked={data.checked} onChange={doCheck} />
              <label htmlFor={data.id}>
                <span className="special-font"></span>
                {data.name}
              </label>
            </div>
          ))}
        {isCheckAllPossible &&
          <div className="list-items-checkbox">
            <input type={type} id={inputCheckAllId} checked={allSelected} onChange={doCheckAll} />
            <label htmlFor={inputCheckAllId}>
              <span className="special-font"></span>
              Tout sélectionner
            </label>
          </div>
        }
      </div>
    </div>
  )

}

export default CustomCheckboxesList
