import React, { useEffect, useRef, useState } from 'react'

import './draggableSliderTab.scss'

const DraggableSliderTab = ({ tabsList, onChange, initialCurrent = null }) => {
  const [current, setCurrent] = useState(initialCurrent)
  const [isDragging, setIsDragging] = useState(false)
  const tabsBox = useRef(null)
  const rightBtn = useRef(null)
  const leftBtn = useRef(null)

  const handleClickTab = (key) => {
    if (isDragging) return
    setCurrent(key)
  }

  const handleScroll = (direction) => {
    let scrollWidth = tabsBox.current.scrollLeft += direction === "left" ? -(tabsBox.current.clientWidth * 0.5) : (tabsBox.current.clientWidth * 0.5)
    handleIcons(scrollWidth)
  }

  const handleIcons = (scrollVal) => {
    let maxScrollableWidth = tabsBox.current.scrollWidth - tabsBox.current.clientWidth
    leftBtn.current.style.display = scrollVal <= 0 ? "none" : "flex"
    rightBtn.current.style.display = maxScrollableWidth - scrollVal <= 1 ? "none" : "flex"
  }

  const dragging = (e) => {
    if (!isDragging) return
    tabsBox.current.classList.add("dragging")
    tabsBox.current.scrollLeft -= e.movementX
    handleIcons(tabsBox.current.scrollLeft)
  }

  const dragStop = () => {
    setIsDragging(false)
    tabsBox.current.classList.remove("dragging")
  }

  const resizer = () => {
    const tabs = tabsBox.current.getElementsByClassName('tab')
    if (tabs.length === 0) return
    const offsetWidth = tabs[0].offsetWidth
    if (tabsBox.current.clientWidth >= (5 * offsetWidth) && leftBtn.current.style.display === 'flex') leftBtn.current.style.display = 'none'
    rightBtn.current.style.display = tabsBox.current.clientWidth > ((5 * offsetWidth) - 50) ? "none" : "flex"
  }

  useEffect(() => {
    window.addEventListener('resize', resizer)
    return () => window.removeEventListener('resize', resizer)
  })

  useEffect(() => {
    rightBtn.current.style.display = tabsBox.current.clientWidth > ((5 * 170) - 50) ? "none" : "flex"
  }, [])

  useEffect(() => {
    onChange(current)
  }, [current])

  useEffect(() => {
    setCurrent(initialCurrent)
  }, [initialCurrent])


  return (
    <div className='--citc-draggable-slider-tab'>
      <span ref={leftBtn} onClick={() => handleScroll('left')} className="icon icon-left icon-angle-left-b"></span>
      <span ref={rightBtn} onClick={() => handleScroll('right')} className="icon icon-right icon-angle-right-b"></span>
      <ul
        className="tabs-box"
        ref={tabsBox}
        onPointerDown={() => setIsDragging(true)}
        onPointerMove={dragging}
        onPointerUp={dragStop}
      >
        {tabsList.map((content, _index) => {
          const tabClassNames = ['tab']

          if (current === content.key) tabClassNames.push('active')

          return <li key={content.key} className={tabClassNames.join(' ')} onClick={() => handleClickTab(content.key)}>{content.name}</li>
        })}
      </ul>
    </div>
  )
}

export default DraggableSliderTab
