import amboise from '../../assets/images/static_pages/home/actors/logo_amboise.png'
import caramba from '../../assets/images/static_pages/home/actors/logo_caramba.png'
import centrePompidouMetz from '../../assets/images/static_pages/home/actors/logo_centre_pompidou_metz.png'
import citeDuVin from '../../assets/images/static_pages/home/actors/logo_cité_du_vin.png'
import centreDesMonumentsNationaux from '../../assets/images/static_pages/home/actors/logo_cmn.png'
import ducDesLombards from '../../assets/images/static_pages/home/actors/logo_duc_des_lombards.png'
import fondationHenriCartierBresson from '../../assets/images/static_pages/home/actors/logo_fondation_henri_cartier_bresson.png'
import scalaParis from '../../assets/images/static_pages/home/actors/logo_la_scala_paris.png'
import pointVirgule from '../../assets/images/static_pages/home/actors/logo_le_point_virgule_couleur.png'
import mucem from '../../assets/images/static_pages/home/actors/logo_Logo_Mucem.png'
import theatreAntoine from '../../assets/images/static_pages/home/actors/logo_LOGO_THEATRE_ANTOINE.png'
import theatreDesMathurin from '../../assets/images/static_pages/home/actors/logo_logos_101_paris_theatre_des_mathurins_petite_salle.jpg'
import chateauDeVersailles from '../../assets/images/static_pages/home/actors/logo_Logotype_Château_de_Versailles_2017.png'
import operaNationalDeLyon from '../../assets/images/static_pages/home/actors/logo_opera_lyon.png'
import orchestreNationalIDF from '../../assets/images/static_pages/home/actors/logo_orchestre_national_d_ile_de_france.png'
import parisMusees from '../../assets/images/static_pages/home/actors/logo_paris_musees.png'
import theatreMontparnasse from '../../assets/images/static_pages/home/actors/logo_paris_theatre_montparnasse.png'
import museeDuQuaiBranly from '../../assets/images/static_pages/home/actors/logo_quai_branly.png'
import grandPalais from '../../assets/images/static_pages/home/actors/logo_Rmn_Grand_Palais.png'
import theatreDeParis from '../../assets/images/static_pages/home/actors/logo_theatre_de_paris.png'
import theatreDesSalinieres from '../../assets/images/static_pages/home/actors/logo_theatre_des_salinieres.png'
import theatreEdouardVII from '../../assets/images/static_pages/home/actors/logo_theatre_edouard_vii.png'
import theatreNationalDeBretagne from '../../assets/images/static_pages/home/actors/logo_theatre_national_de_bretagne.png'
import theatreRiveGauche from '../../assets/images/static_pages/home/actors/logo_theatre_rive_gauche.jpg'
import theatreNationalStrasbourg from '../../assets/images/static_pages/home/actors/logo_tns.png'

export default {
  'theatre-de-paris': { src: theatreDeParis, alt: 'Théâtre de Paris' },
  'scala-paris': { src: scalaParis, alt: 'Scala Paris' },
  'theatre-montparnasse': { src: theatreMontparnasse, alt: 'Théâtre Montparnasse' },
  'theatre-antoine': { src: theatreAntoine, alt: 'Théâtre Antoine' },
  'theatre-national-strasbourg': { src: theatreNationalStrasbourg, alt: 'Théâtre national de Strasbourg' },
  'theatre-des-salinieres': { src: theatreDesSalinieres, alt: 'Théâtre des Salinières' },
  'grand-palais': { src: grandPalais, alt: 'Grand Palais' },
  'musee-du-quai-branly': { src: museeDuQuaiBranly, alt: 'Musée du quai Branly' },
  'paris-musees': { src: parisMusees, alt: 'Paris Musées' },
  'centre-des-monuments-nationaux': { src: centreDesMonumentsNationaux, alt: 'Centre des monuments nationaux' },
  mucem: { src: mucem, alt: 'Musée des civilisations de l\'Europe et de la Méditerranée' },
  'cite-du-vin': { src: citeDuVin, alt: 'Cité du Vin' },
  'opera-national-de-lyon': { src: operaNationalDeLyon, alt: 'Opéra National de Lyon' },
  'orchestre-national-idf': { src: orchestreNationalIDF, alt: 'Orchestre national d\'Île-de-France' },
  caramba: { src: caramba, alt: 'Caramba Culture Live' },
  // 'centre-pompido-metz': { src: centrePompidouMetz, alt: 'Centre Pompidou-Metz' },
  // 'chateau-amboise': { src: amboise, alt: 'Château Royal d\'Amboise' },
  // 'duc-des-lombards': { src: ducDesLombards, alt: 'Duc des Lombards' },
  // 'fondation-henri-cartier-bresson': { src: fondationHenriCartierBresson, alt: 'Fondation Henri Cartier-Bresson' },
  // 'point-virgule': { src: pointVirgule, alt: 'Point Virgule' },
  // 'theatre-des-mathurin': { src: theatreDesMathurin, alt: 'Théâtre des Mathurin' },
  // 'chateau-de-versailles': { src: chateauDeVersailles, alt: 'Château_de_Versailles' },
  // 'theatre-edouard-vii': { src: theatreEdouardVII, alt: 'Théâtre Édouard VII' },
  // 'theatre-national-de-bretagne': { src: theatreNationalDeBretagne, alt: 'Théâtre National de Bretagne' },
  // 'theatre-rive-gauche': { src: theatreRiveGauche, alt: 'Théâtre Rive Gauche' },
}
