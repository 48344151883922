import React from 'react'

import theatreImg from '../../assets/images/themeCards/theatre&show.jpg'
import concertImg from '../../assets/images/themeCards/concert.jpg'
import museumImg from '../../assets/images/themeCards/museum.jpg'
import multiImg from '../../assets/images/themeCards/multi.jpg'

import ThemeCard from './themeCard';

import './themeCard.scss'


const ThemeCards = () => {
	return (
		<div className='theme-cards-container'>
			<h1 className='theme-cards-title'>Nos Thématiques</h1>
			<div className='theme-cards-list'>
				<ThemeCard
					title='Théâtre & Spectacles'
					image={theatreImg}
					color='red'
					info1='1200 spectacles / mois'
					info2='450 salles en France'
					slug='coffret-spectacle'
				/>
				<ThemeCard
					title='Concerts'
					image={concertImg}
					color='blue'
					info1='600 concerts / mois'
					info2='250 salles en France'
					slug='coffret-concert'
				/>
				<ThemeCard
					title='Musées & Monuments'
					image={museumImg}
					color='green'
					info1='500 expositions et visites / mois'
					info2='250 institutions en France'
					slug='coffret-musees-monuments'
				/>
				<ThemeCard
					title='Multi-thématiques'
					image={multiImg}
					color='yellow'
					info1='2200 événements / mois'
					info2='1000 lieux culturels en France'
					slug='coffret-multiculturels'
				/>
			</div>
		</div>
	)
}

export default ThemeCards;
