import { buildURI } from "./utils"

const Api = () => {
  const _request = ({ path, method = 'GET', params = {}, body = [] }) => {
    let options = { method, headers: { 'Content-Type': 'application/json' } }
    let url = buildURI(path, params)

    if (method === 'POST') options.body = JSON.stringify(body)

    return fetch(url, options).then(response => response.json())
  }

  const shows = () => {
    const index = ({ page = 1, perPage = 20, ...params } = {}) => {
      return _request({ path: '/api/shows.json', params: { page, per_page: perPage, ...params } })
    }

    const aggregates = (params = {}) => {
      return _request({ path: '/api/shows/aggregates.json', params: params })
    }

    return { index, aggregates }
  }

  return {
    shows: shows()
  }
}

export default Api()
