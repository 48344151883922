import React, { useEffect, useRef, useState } from 'react'
import CustomButton from './CustomButton'
import ahoy from 'ahoy.js'

import './newsLetter.scss'
import { CustomErrorPane } from './CustomPane'

const NewsLetter = () => {
  const [value, setValue] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [succesMessage, setSuccesMessage] = useState(null)
  const formRef = useRef(null)

  const baseUrl = `${window.location.protocol}//${window.location.host}`

  const doClickOutsidePane = () => {
    setErrorMessage(null)
    setSuccesMessage(null)
  }

  const doChange = event => {
    setValue(event.target.value)
    setErrorMessage(null)
  }

  const doSubmit = (event) => {
    event.preventDefault()

    setSuccesMessage(null)
    setErrorMessage(null)

    if (!value || value.trim().length === 0) {
      setErrorMessage('Veuillez renseigner ce champ.')
      return
    }

    if (!/^[^@\s]+@[^@\s]+$/.test(value)) {
      setErrorMessage('Veuillez saisir une adresse e-mail valide.')
      return
    }

    const form = formRef.current
    const url = form.getAttribute('action')
    const method = form.getAttribute('method') || 'POST'

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: value })
    }).then(_response => {
      setSuccesMessage('Merci de votre inscription.')

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up',
        ecommerce: { method: 'newsletter' }
      })

      ahoy.track('sign_up', { event: 'sign_up', ecommerce: { method: 'newsletter' }, ahoy_type: 'newsletter' })
    }).catch((_error) => {
      setErrorMessage('Une erreur est survenue.')
    })

    setValue('')
  }

  useEffect(() => {
    window.addEventListener('click', doClickOutsidePane)
    return () => window.removeEventListener('click', doClickOutsidePane)
  })

  return (
    <div className="news-letter-container">
      <div className="news-letter-content">
        <h2>Envie de recommandations ?</h2>
        <p>Recevez nos sélections de spectacles, expositions et concerts ainsi que des offres exclusives&nbsp;!</p>
        <form ref={formRef} action={`${baseUrl}/newsletter`} method="POST" noValidate={true} onSubmit={doSubmit}>
          <fieldset>
            <span className="icon" />
            <input name="email" value={value} type="email" placeholder="Votre adresse mail" onChange={doChange} />
            {errorMessage && <CustomErrorPane style="warning" messages={errorMessage} overflowY={48} />}
            {succesMessage && <CustomErrorPane style="success" messages={succesMessage} overflowY={48} />}
          </fieldset>
          <CustomButton tagName="button" text="Je m'inscris" style="broke-corner" iconLeft="&#xeb4d;" color="black" />
        </form>
      </div>
    </div>
  )
}

export default NewsLetter
