import React from 'react'
import { visit } from './utils'

import './themeCard.scss'


const ThemeCard = ({ title, image, color, info1, info2, slug }) => {

  const baseUrl = `${window.location.protocol}//${window.location.host}`

  const handleClick = () => {
    visit(`${baseUrl}/coffrets-cadeaux/${slug}`)
  }

  return (
    <>
      <div className={`theme-card-container theme-card-${color}`} onClick={handleClick}>
        <div className="theme-card-image">
          <img className="theme-card-image-resized" src={image} alt="theme" />
        </div>
        <div className="theme-card-title">
          <h2>{title}</h2>
          <p className="theme-card-text">{info1}</p>
          <p className="theme-card-text">{info2}</p>
          <a className="theme-card-link">En savoir plus <span className="special-font">&#xec06;</span></a>
        </div>
      </div>
    </>
  )
}

export default ThemeCard
