import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CustomPane from './CustomPane'
import { uuidV4 } from './utils'

import './CustomSelect.scss'

const CustomSelect = ({ checkable, label, multiple, noResultsMessage, onChange, options, placeHolder, searchable }) => {
  const [containerId, setContainerId] = useState(null)
  const [showedPane, setShowedPane] = useState(false)
  const [value, setValue] = useState(multiple || checkable ? [] : null)

  const doOpenOrClose = () => setShowedPane(!showedPane)
  const doClickOutsidePane = (event) => !event.target.closest(`#${containerId}`) && showedPane && setShowedPane(false)

  const doChange = (keys) => {
    if (!multiple && !checkable) setShowedPane(false)

    setValue(multiple || checkable ? keys : (keys[0] || null))
  }

  useEffect(() => {
    window.addEventListener('click', doClickOutsidePane)
    return () => window.removeEventListener('click', doClickOutsidePane)
  }, [showedPane])

  useEffect(() => {
    setContainerId(`custom-select-${uuidV4()}`);
  }, [])

  useEffect(() => {
    if ((typeof onChange) === 'undefined') {
      console.warn(`Onchange function is not defined on this select. (${containerId} : ${label})`)
      return
    }

    onChange(value)
  }, [value])

  return (
    <div id={containerId} className={`--citc-custom-${multiple || checkable ? 'multiple' : 'single'}-select`}>
      {label && (
        <div className="label" onClick={doOpenOrClose}>{label}</div>
      )}
      <div className={`select ${showedPane ? 'open' : 'close'}`}>
        <div className="values" onClick={doOpenOrClose}>
          {multiple || checkable ? (
            value.length !== 0 ? (
              value.map(key => (
                <span className="value">{options[key].label}</span>
              ))
            ) : (
              <span className="placeholder">{placeHolder}</span>
            )
          ) : (
            value ? (
              <span className="value">{options[value].label}</span>
            ) : (
              <span className="placeholder">{placeHolder}</span>
            )
          )}
        </div>
        <div className="arrow" onClick={doOpenOrClose}></div>
        <CustomPane checkable={checkable} multiple={multiple} noResultsMessage={noResultsMessage} onChange={doChange} options={options} overflowY={30}
                    searchable={searchable} showed={showedPane} />
      </div>
    </div>
  )
}

CustomSelect.propTypes = {
  checkable: PropTypes.bool,
  label: PropTypes.string,
  noResultsMessage: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.object,
  placeHolder: PropTypes.string,
  searchable: PropTypes.bool,
}

export default CustomSelect
