import React, { useRef, useState } from 'react'
import CustomButton from './CustomButton'
import EventTile from './eventTile'
import { showsTrackingParams, visit } from './utils'

import './eventTiles.scss'

const EventTiles = ({ data, listId = null, listName = null, showTitle, showButton }) => {
  const scrl = useRef(null)
  const [firstStep, setFirstStep] = useState(false)
  const isShowedTitle = typeof showTitle === 'undefined' || isShowedTitle
  const isShowedButton = typeof showButton === 'undefined' || isShowedButton

  const scrollForward = () => {
    const scrn = window.screen.width
    if (scrn >= 1080) {
      scrl.current.scrollLeft += 309
    } else {
      if (firstStep) {
        scrl.current.scrollLeft += 269
      } else {
        setFirstStep(true)
        scrl.current.scrollLeft += 220
      }

    }
  }

  const scrollBackward = () => {
    const scrn = window.screen.width
    if (scrn >= 1080) {
      if (scrl.current.scrollLeft - 269 <= 0) {
        setFirstStep(false)
      }
      scrl.current.scrollLeft += -309
    } else {
      if (scrl.current.scrollLeft - 269 <= 0) {
        setFirstStep(false)
      }
      scrl.current.scrollLeft += -269
    }
  }

  return (
    <div className="event-tiles-container" data-controller="tracking" data-tracking-auto-track-type-value="show"
         data-tracking-auto-track-name-value="view_item_list"
         data-tracking-auto-track-params-value={JSON.stringify(showsTrackingParams(data, { list_id: listId, list_name: listName }))}>
      {isShowedTitle && <h1 className="event-tiles-title">À retrouver dans nos coffrets</h1>}
      <span className="icon-arrow-left event-tiles-rounded-icons" onClick={scrollBackward}></span>
      <div ref={scrl} className="event-tiles-list">
        {
          data.map((content, index) => (
            <EventTile
              key={content.slug}
              showId={content.id}
              showName={content.name}
              showType={content.show_type}
              showSlug={content.slug}
              showVenue={content?.venue?.name}
              showCity={content?.venue?.city}
              listid={listId}
              listname={listName}
              itemIndex={index}
              format="black-fade"
            />
          ))
        }
      </div>
      <span className="icon-arrow-right event-tiles-rounded-icons" onClick={scrollForward}></span>
      {isShowedButton && <CustomButton text="Voir tous nos événements" style="broke-corner" onClick={() => visit('/evenements')} />}
    </div>
  )
}

export default EventTiles
