import React from 'react'
import EventTile from './eventTile'

import './showsResults.scss'
import CustomButton from './CustomButton'

const ShowsResults = ({ listId = null, listName = null, results, resetFilter }) => {
  const hasParams = window.location.search.length > 0

  return (
    <div className="--citc-shows-results">
      <div className="results">
        {results.map((content, index) => (
          <EventTile
            key={content.slug}
            showId={content.id}
            showName={content.name}
            showSlug={content.slug}
            showVenue={content.venue.name}
            showCity={content.venue.city}
            showStartDate={content.starts_at}
            showEndDate={content.ends_at}
            showCategory={content.categories}
            showRating={content.rating}
            showVenueSlug={content.venue.slug}
            listid={listId}
            listname={listName}
            itemIndex={index}
            format="neutral"
          />
        ))}
      </div>
      {results.length === 0 && (
        <div className="no-result">
          <h3>Nous n’avons trouvé aucun résultat correspondant à vos recherches...</h3>
          <p>Pensez à élargir vos critères de recherche pour avoir plus de possibilités</p>
          <CustomButton text="Effacer les filtres" style="broke-corner" color="black" onClick={() => resetFilter()} />
        </div>
      )}
      {(results.length > 0 && results.length <= 5 && hasParams) && (
        <div className="low-result">
          <h3>Vous ne trouvez pas votre bonheur ?</h3>
          <p>Pour élargir votre recherche, effacez les filtres</p>
          <CustomButton text="Effacer les filtres" style="broke-corner" color="black" onClick={() => resetFilter()} />
        </div>
      )}
    </div>
  )
}

export default ShowsResults
