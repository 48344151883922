import React from "react";

import './aboutUs.scss'

import team from '../../assets/images/static_pages/brand/team.jpg'

const AboutUs = () => {
  return (
    <div className="--citc-about-us">
      <h1>QUI SOMMES-NOUS ?</h1>
      <img src={team} />
      <div>
      <p>Fondé en <span> 2014 </span> par Benjamin Bir, comédien, Cultur’in the City est avant tout une équipe
        de férus de culture basée à Paris qui s’applique à dénicher de nouveaux partenariats chaque mois,
        <span> pour vous proposer le meilleur des événements culturels partout en France</span>.
        Le résultat : des coffrets cadeaux originaux pour tous les budgets et tous les goûts&nbsp;!</p>
      <p>Envie d’assister à la dernière grande pièce à succès dans une salle mythique ?
        De découvrir des pépites dans des salles intimistes ou les futurs talents de l’humour dans un comedy club ?
        De profiter de concerts de jazz ou de musique classique ? De visiter les plus beaux musées
        et expositions du moment ? Les coffrets Cultur’In The City sont faits pour vous&nbsp;!</p>
      <p>Ce qui nous anime chaque jour, c’est de <span>donner envie au plus grand nombre d’aller au théâtre, au musée</span>,
        ou dans <span>des salles de concert</span>, aussi bien pour des sorties du quotidien que pour de plus grandes occasions.
        Nous proposons un catalogue d'événements variés et mis à jour régulièrement en fonction de la programmation,
        pour vous donner l’accès aux meilleurs événements culturels à ne pas manquer, partout en France.</p>
      <p>Faites nous confiance et <span>laissez-vous accompagner pour faire plaisir à vos proches avec
        des cadeaux qui sortent de l’ordinaire&nbsp;!</span>
      </p>
      </div>
    </div>
  )
}

export default AboutUs
