import React, { useState } from "react"

import './toggableSection.scss'

const ToggableSection = ({ children, title, isOpened = true }) => {
  const [hidden, setHidden] = useState(!isOpened)
  const sectionClassNames = ['--citc-toggable-section']

  if (hidden) sectionClassNames.push('closed')

  return (
    <div className={sectionClassNames.join(' ')}>
      <div className="toggle-title" onClick={() => setHidden(!hidden)}>
        <h2>{title}</h2>
        <span className="special-font toggle-section-icon">&#xec06;</span>
      </div>
      <div className="content">
        {children}
      </div>
    </div>
  )
}

export default ToggableSection
