import React from 'react'
import CustomButton from './CustomButton'
import Tooltip from './tooltip'
import { baseUrl, showTrackingParams, visit } from './utils'

const EventTile = ({
  itemIndex = null, listId = null, listName = null, showId = null,
  showName, showType, showSlug, showVenue, showCity, showStartDate, showEndDate, showCategory, showRating, format, showVenueSlug
}) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  const startDate = showStartDate ? new Date(Date.parse(showStartDate)).toLocaleDateString('fr-FR', options) : null
  const endDate = showEndDate ? new Date(Date.parse(showEndDate)).toLocaleDateString('fr-FR', options) : null

  const getImageRenderer = (dataSlug) => {
    return `${baseUrl}/shows/${dataSlug}/representations/medium/thumbnail.png`
  }

  const handleClickSeeEvent = (dataSlug) => {
    visit(`${baseUrl}/evenements/${dataSlug}`)
  }

  const getDominantColor = (dataSlug) => {
    const canvas = document.getElementById(`background-canvas-${dataSlug}`)

    if (canvas == null) return

    const ctx = canvas.getContext('2d')
    const image = document.getElementById(`image-canvas-${dataSlug}`)

    ctx.drawImage(image, 0, 0, 1, 1)
  }

  return (
    <>
      {format === 'black-fade' &&
        <div className="event-tile-container" style={{ backgroundImage: `url(${getImageRenderer(showSlug)})` }} onClick={() => handleClickSeeEvent(showSlug)}
          data-controller="tracking" data-tracking-track-type-value="product" data-tracking-track-name-value="select_item"
          data-tracking-track-params-value={JSON.stringify(showTrackingParams({
            id: showId,
            name: showName,
            venue: showVenue,
            category: showType,
            url: `${baseUrl}/evenements/${showSlug}`,
            position: itemIndex
          }, { list_id: listId, list_name: listName }))}>
          <div className="event-tile-content">
            <p className="event-tile-title">{showName}</p>
            <p className="event-tile-tag">{showType}</p>
            {showVenue &&
              <p className="event-tile-locate">
                <span className="icon-building"></span>
                {showVenue}
              </p>
            }
            {showCity && showCity.length > 0 &&
              <p className="event-tile-locate">
                <span className="icon icon-location-pin-alt"></span>
                {showCity}
              </p>
            }
          </div>
        </div>
      }
      {format === 'neutral' &&
        <div className="--citc-event-tile">
          <div className="image-rating">
            <canvas className="canvas-background" id={`background-canvas-${showSlug}`} width="1px" height="1px"></canvas>
            <img id={`image-canvas-${showSlug}`} src={`${getImageRenderer(showSlug)}`} onLoad={() => getDominantColor(showSlug)}
              onClick={() => handleClickSeeEvent(showSlug)} />
            {
              showRating > 0 &&
              <p className="rating" onClick={() => visit(`${baseUrl}/evenements/${showSlug}#show-reviews`)}>
                <span className="icon">&#xeb24;</span>
                {showRating}
              </p>
            }
          </div>
          <div className="category">
            {
              showCategory !== 'undefined' && showCategory.length > 0 &&
              <CustomButton text={showCategory[0].name} size="sm" onClick={() => visit(`${baseUrl}/evenements?categories=${showCategory[0].slug}`)}
                overwriteColor="#2B394D" overwriteBackgroundColor="#dbdbdb" />
            }
          </div>
          <Tooltip text={showName} minLength={20}>
            <h2 onClick={() => handleClickSeeEvent(showSlug)}>{showName}</h2>
          </Tooltip>
          <p className="dates">
            <span className="icon icon-calendar-alt"></span>
            {`${startDate}${endDate && endDate !== startDate ? ' - ' + endDate : ''}`}
          </p>
          <div className="locates">
            <p onClick={() => visit(`${baseUrl}/lieux-partenaires/${showVenueSlug}`)}>
              <span className="icon icon-building"></span>
              {showVenue}
            </p>
            <p>
              <span className="icon icon-location-pin-alt"></span>
              {showCity}
            </p>
          </div>
        </div>
      }
    </>
  )
}

export default EventTile
