import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { uuidV4 } from './utils'

import 'react-dates/initialize'

import './CustomInputDate.scss'

import 'react-dates/lib/css/_datepicker.css'
import './react_dates_overrides.scss'
import CustomButton from './CustomButton'

const CustomInputDate = ({ alwaysShow, direction, icon, initialEndDate, initialStartDate, onChange, onSubmit, placeholder, title, reset }) => {
  const [startDate, setStartDate] = useState(initialStartDate || null)
  const [endDate, setEndDate] = useState(initialEndDate || null)
  const [focusedInput, setFocusedInput] = useState(alwaysShow ? 'startDate' : null)
  const [inputEndDateId, setInputEndDateId] = useState(initialEndDate || null)
  const [inputStartDateId, setInputStartDateId] = useState(initialStartDate || null)
  const [selectedRangeButton, setSelectedRangeButton] = useState(null)
  const dataPickerClassName = []

  const checkStartEndEquality = () => (initialStartDate === initialEndDate ? dataPickerClassName.push('same') : '')

  if (initialEndDate) {
    checkStartEndEquality();
  }
  else {
    dataPickerClassName.push('same')
  }

  moment.locale('fr')

  const setDates = ({ startDate, endDate }) => {
    setStartDate(startDate)
    if (startDate === null || endDate === null || endDate.isSame(startDate)) setEndDate(null)
    else setEndDate(endDate)
    if ((typeof onChange) === 'undefined') console.warn(`onchange function is not defined on this input. (${title})`)
    else onChange(startDate, endDate)
    if ((typeof onSubmit) !== 'undefined' && startDate !== null && endDate !== null) onSubmit()
  }
  const setFocused = (event) => {
    if (alwaysShow && !event) event = 'startDate'
    return setFocusedInput(event)
  }

  const handleThisWeekendClick = () => {
    const today = moment().startOf('day')
    setSelectedRangeButton('weekend')

    if (today.isoWeekday() === 7) {
      setDates({ startDate: today, endDate: today })
    } else {
      const firstDayOfWeekend = today.clone().endOf('week').subtract(1, 'day')
      const secondDayOfWeekend = today.clone().endOf('week').endOf('day')
      setDates({ startDate: firstDayOfWeekend, endDate: secondDayOfWeekend })
    }
  }

  const handleThisWeekClick = () => {
    const today = moment().startOf('day')
    const weekEnd = today.clone().endOf('week').endOf('day')
    setDates({ startDate: today, endDate: weekEnd })
    setSelectedRangeButton('week')
  }

  useEffect(() => {
    setInputEndDateId(`input-date-${uuidV4()}`)
    setInputStartDateId(`input-date-${uuidV4()}`)
  }, [])

  useEffect(() => {
    onChange(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    if (alwaysShow) return
    if (inputEndDateId == null) return
    const inputOnDate = document.getElementById(inputEndDateId)
    if (inputOnDate == null) return
    if (startDate === null || endDate === null || endDate.isSame(startDate)) inputOnDate.parentElement.style.display = 'none'
    else inputOnDate.parentElement.style.display = 'inline-block'
  }, [inputEndDateId, startDate, endDate])

  if (alwaysShow) dataPickerClassName.push('always-show')
  else dataPickerClassName.push('default')

  useEffect(() => {
    setSelectedRangeButton(null)
    setDates({ startDate: null, endDate: null })
  }, [reset])

  return (
    <div className="--citc-custom-input-date">
      {!alwaysShow && title && <label>{title}</label>}
      <div className={dataPickerClassName.join(' ')}>
        <div className='date-selector'>
          <DateRangePicker
            startDate={initialStartDate || startDate}
            startDateId={inputStartDateId}
            startDatePlaceholderText={placeholder}
            endDate={initialEndDate || endDate}
            endDateId={inputEndDateId}
            endDatePlaceholderText={''}
            onDatesChange={({ startDate, endDate }) => {
              setSelectedRangeButton(null)
              setDates({ startDate, endDate })
            }}
            focusedInput={focusedInput}
            onFocusChange={setFocused}
            customArrowIcon={endDate ? 'au' : <React.Fragment />}
            inputIconPosition={'before'}
            customInputIcon={<span className="icon">{icon}</span>}
            displayFormat={'DD/MM/yyyy'}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            showClearDates={true}
            minimumNights={0}
            verticalSpacing={0}
            openDirection={direction ? direction : 'auto'}
          />
          {(alwaysShow && (startDate || initialStartDate)) &&
            <div className="selected-dates">
              <p>
                <span className='selected-date'>{`${initialStartDate?.format('DD/MM/yyyy')}`}</span>
                {(endDate || initialEndDate) && <>
                  <span> au </span>
                  <span className='selected-date'>{`${initialEndDate?.format('DD/MM/yyyy')}`}</span>
                </>}
              </p>
              <span className="icon icon-times-circle" onClick={() => {
                setSelectedRangeButton(null)
                setDates({ startDate: null, endDate: null })
              }
              }></span>
            </div>
          }
        </div>
        <div className="preselect">
          <CustomButton text="Aujourd'hui" size="custom-calendar"
            style={selectedRangeButton && selectedRangeButton === 'today' ?
              ''
              : 'no-background'}
            color={selectedRangeButton && selectedRangeButton === 'today' ?
              'primary'
              : 'white'}
            onClick={() => {
              setDates({
                startDate: moment().endOf('day'),
                endDate: moment().endOf('day')
              })
              setSelectedRangeButton('today')
            }} />
          <CustomButton text="Demain" size="custom-calendar"
            style={selectedRangeButton && selectedRangeButton === 'tomorrow' ?
              ''
              : 'no-background'}
            color={selectedRangeButton && selectedRangeButton === 'tomorrow' ?
              'primary'
              : 'white'}
            onClick={() => {
              setDates({
                startDate: moment().add(1, 'day').endOf('day'),
                endDate: moment().add(1, 'day').endOf('day')
              })
              setSelectedRangeButton('tomorrow')
            }} />
          <CustomButton text="Ce weekend" size="custom-calendar"
            style={selectedRangeButton && selectedRangeButton === 'weekend' ?
              ''
              : 'no-background'}
            color={selectedRangeButton && selectedRangeButton === 'weekend' ?
              'primary'
              : 'white'}
            onClick={handleThisWeekendClick} />
          <CustomButton text="Cette semaine" size="custom-calendar"
            style={selectedRangeButton && selectedRangeButton === 'week' ?
              ''
              : 'no-background'}
            color={selectedRangeButton && selectedRangeButton === 'week' ?
              'primary'
              : 'white'}
            onClick={handleThisWeekClick} />
        </div>
      </div>
    </div>
  )
}

CustomInputDate.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
}
export default CustomInputDate
