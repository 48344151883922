import React from 'react'
import { visit } from './utils'

const BestSeller = ({ data, length }) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  const len = length ? length : data.length

  const getImageRenderer = (dataSlug) => {
    return `${baseUrl}/products/${dataSlug}/representations/default/thumbnail.png`
  }

  return (
    <div className="--citc-best-seller">
      <h1>Nos meilleures ventes</h1>
      <div className="products">
        {data.slice(0, len).map(content => (
          <div className="tile" onClick={() => visit(`${baseUrl}/coffrets-cadeaux/${content.path}`)}>
            <img src={getImageRenderer(content.path)} alt="product" />
            <p className="name">{content.name}</p>
            <p className="price">{content.price},00€</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BestSeller
