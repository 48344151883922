import React from 'react'
import CustomButton from './CustomButton'
import { visit } from './utils'

import './customMenu.scss'

const CustomMenu = ({ data }) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`

  return (
    <div className="--citc-custom-menu">
      <ul>
        {data?.map((content, key) => {
          const classNames = []
          const cssStyle = {}

          if (content.bold) classNames.push('bold')

          if (content.color?.charAt(0) === "#") {
            classNames.push('custom-color')
            cssStyle['--override-color'] = content.color
          } else {
            switch (content.color) {
              case 'red':
                classNames.push(content.color)
                break
            }
          }

          return (<li key={key}>
            {content.type === 'link' && <a className={classNames.join(' ')} style={cssStyle} href={baseUrl + content.path}
                                           onClick={() => visit(baseUrl + content.path)}>{content.title}</a>}
            {content.type === 'see_all' &&
              <CustomButton text="Tout voir" style="underline" color="primary" onClick={() => visit(baseUrl + content.path)} />}
          </li>)
        })}
      </ul>
    </div>
  )
}

export default CustomMenu
