import React from "react"

import './tag.scss'

const Tag = ({ text, color, info }) => {
  const classNames = ['--citc-custom-tag']

  switch (color) {
    case 'physic':
      classNames.push(color)
      break

    default:
      classNames.push('e-product')
  }

  return (
    <div className={classNames.join(' ')}>
      <p className={info ? 'info' : ''} aria-label={info}>{text}</p>
    </div>
  )
}
export default Tag
