import React from "react"
import CustomButton from "./CustomButton";
import './offersEnterpriseView.scss'
import starsLogo from '../../assets/images/icons/stars-stars.svg'
import thumbLogo from '../../assets/images/icons/thumb.svg'
import ticketLogo from '../../assets/images/icons/ticket.svg'
import heartLogo from '../../assets/images/icons/heart.svg'
import sunLogo from '../../assets/images/icons/sun.svg'
import giftLogo from '../../assets/images/icons/gift.svg'
import cardsImg from '../../assets/images/products/cards.png'
import customableImg from '../../assets/images/products/customable.png'
import theatreImg from '../../assets/images/products/theatre.png'
import parisImg from '../../assets/images/products/paris.png'

const OffersEnterpriseView = () => {

  const scrollToForm = () => {
    const textElement = document.getElementById("form")

    textElement.scrollIntoView({ behavior: "smooth" })

  }

  return (
    <>
      <div className='offers-enterprise-page'>
        <div className='offers-enterprise-banner'>
          <p>Remerciez vos
            <span className='offers-enterprise-banner-gold'> clients et collaborateurs </span>
            avec des places de spectacles
            <span className='offers-enterprise-banner-gold'> à tarifs préférentiels</span>
          </p>
          <CustomButton text="Ça m'intéresse !" color="red" boxShadow="black-shadow" style="broke-corner" onClick={scrollToForm} />
          {/*<span className='special-font'>&#xe992;</span>*/}
        </div>

        <div className='offers-enterprise-infos'>
          <div className='offers-enterprise-info'>
            <img src={ticketLogo} alt='logo' />
            <h2>Pour tous les budgets</h2>
            <span className='offers-enterprise-info-line'></span>
            <p>Places de spectacles
              <span className='offers-enterprise-info-bolded-text'> à partir de 15€ </span>
              Produits
              <span className='offers-enterprise-info-bolded-text'> physiques </span>
              ou
              <span className='offers-enterprise-info-bolded-text'> e-billets </span>
            </p>
          </div>
          <div className='offers-enterprise-info'>
            <img src={starsLogo} alt='logo' />
            <h2>N°1 du cadeau culturel français</h2>
            <span className='offers-enterprise-info-line'></span>
            <p>
              <span className='offers-enterprise-info-bolded-text'> + de 1000 </span>
              lieux et partenaires
              <span className='offers-enterprise-info-bolded-text'> théâtres, spectacles, concerts, musées</span>
              ...
            </p>
          </div>
          <div className='offers-enterprise-info'>
            <img src={thumbLogo} alt='logo' />
            <h2>Utilisation ultra simple</h2>
            <span className='offers-enterprise-info-line'></span>
            <p>Réservations 100% en ligne. <br />Validité
              <span className='offers-enterprise-info-bolded-text'> 3 ans et 3 mois</span>
            </p>
          </div>
        </div>

        <div className="info">
          <h2>Vous souhaitez offrir à vos salariés l'accès des produits Cultur'In The City?</h2>
          <p>Profitez-en, nos prestations sont exonérées de cotisations et contributions sociales
            au titre des exonérations offertes pour les chèques culture
            (sous réserve de l’analyse réalisée par votre URSSAF).
          </p>
        </div>

        <div className='offers-enterprise-examples-occasions-container'>
          <div className='offers-enterprise-examples'>
            <h2>Quelques exemples de produits...</h2>
            <div className='offers-enterprise-examples-products'>
              <div className='offers-enterprise-examples-product'>
                <img src={cardsImg} alt='product' />
                <p>Places à l’unité</p>
              </div>
              <div className='offers-enterprise-examples-product'>
                <img src={theatreImg} alt='product' />
                <p>Coffrets cadeaux thématiques</p>
              </div>
              <div className='offers-enterprise-examples-product'>
                <img src={customableImg} alt='product' />
                <p>Coffrets personnalisés </p>
              </div>
              <div className='offers-enterprise-examples-product'>
                <img src={parisImg} alt='product' />
                <p>Coffrets villes et régions</p>
              </div>
            </div>
            <p className='offers-enterprise-examples-end'>Choisissez vos nombres de places, vos lieux <br /> ou événements favoris...  </p>
          </div>

          <span className='offers-enterprise-col-separator'></span>

          <div className='offers-enterprise-examples'>
            <h2>...pour toutes les occasions</h2>
            <div className='offers-enterprise-examples-tag-container'>
              <div className='offers-enterprise-examples-tag-occasion'>
                <img src={giftLogo} alt='logo' />
                <p>CADEAUX CLIENTS</p>
              </div>
              <p className='offers-enterprise-examples-occasion-desc'>
                Comme les Laboratoires Roche,
                <span> célébrez les moments </span>
                forts de l’année avec vos collaborateurs et clients en leur offrant un cadeau
                <span> à votre image </span>
              </p>
            </div>
            <div className='offers-enterprise-examples-tag-container'>
              <div className='offers-enterprise-examples-tag-occasion'>
                <img src={heartLogo} alt='logo' />
                <p>TEAM BUILDING</p>
              </div>
              <p className='offers-enterprise-examples-occasion-desc'>
                Comme Bouygues Bâtiment,
                <span> soudez </span>
                et remerciez vos équipes autour de <span> sorties culturelles inoubliables</span>
              </p>
            </div>
            <div className='offers-enterprise-examples-tag-container'>
              <div className='offers-enterprise-examples-tag-occasion'>
                <img src={sunLogo} alt='logo' />
                <p>WELCOME KIT</p>
              </div>
              <p className='offers-enterprise-examples-occasion-desc'>
                Comme Panopli,
                <span> accueillez & fidélisez </span>
                vos nouvelles recrues grâce à une attention
                <span> originale et porteuse de sens</span>
              </p>
            </div>
            <p className='offers-enterprise-examples-end'>Et tant d’autres occasions :
              incentives, jeux concours, événements d’entreprise, arbre de Noël... </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default OffersEnterpriseView;
