import React, { useState, useMemo, useEffect } from 'react'
import CustomInput from './CustomInput'
import { transliterate } from 'transliteration'

import './CheckboxesListButton.scss'
import CustomCheckboxesList from './CustomCheckboxesList'

const CheckboxesListButton = ({
  showedInput, addable, icon, dataList, onChange, options, placeholder, possibilityCheckAll, selectable, title,
  initialValues = [], type = 'checkbox', reset
}) => {
  // State with list of all checked item
  const [customCheckableList, setCustomCheckableList] = useState({})
  const [customValues, setCustomValues] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const isShowedInput = typeof showedInput === 'undefined' ? true : showedInput
  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  const customSelectItem = (itemLabel) => {
    const itemExists = Object.entries(dataList)
      .filter(([_key, { label }]) => transliterate(label.toLowerCase()).search(transliterate(itemLabel.trim().toLowerCase())) !== -1)
      .map(([key, data]) => data?.key || key)

    if (itemExists.length !== 0) {
      setCustomValues([...customValues, itemExists[0]])
    } else {
      const label = itemLabel.split(' ').map((str) => str.split('-').map(capitalize).join('-')).join(' ')


      setCustomCheckableList({ ...customCheckableList, [label]: { key: label, label: label } })
      setCustomValues([...customValues, label])
    }

    setSearchTerm(null)
  }

  const handleClickCheck = (event) => {
    if (event === null) return
    customSelectItem(event.label)
    onChange([...new Set([...customValues])])
  }

  const doChange = (event) => {
    Object.entries(customCheckableList).forEach(([key, _data]) => {
      customCheckableList[key].checked = event.includes(key)
    })

    setCustomCheckableList({ ...customCheckableList })
    setCustomValues(event)
    onChange(event)
  }

  const  checkboxValues = useMemo(() => {
    return [...new Set([...initialValues, ...customValues])];
  }, [customValues, initialValues])

  useEffect(() => {
    if(customValues.length === initialValues.length) return
    onChange(customValues)
  }, [customValues])

  useEffect(() => {
    if(!reset) return
    setCustomValues([])
  }, [reset])

  return (
    <>
      <label className="checkboxes-list-title">{title}</label>
      {isShowedInput &&
        <>
          {selectable &&
            <CustomInput placeHolder={placeholder} onSelect={handleClickCheck} onChange={setSearchTerm} initialValue={searchTerm}
              autoCompleteOptions={options} style="default-input" icon={icon} minLength={0} noResultsMessage="Aucune ville trouvée" reset={reset} />}
        </>
      }
      {addable && isShowedInput && <p className="checkboxes-input-hint">Validez pour ajouter un choix à la liste</p>}
      <CustomCheckboxesList type={type} options={{ ...dataList, ...customCheckableList }}
        initialValues={checkboxValues}
        onChange={doChange} possibilityCheckAll={possibilityCheckAll} />
    </>
  )

}

export default CheckboxesListButton
