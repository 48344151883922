import React, { useState } from 'react'
import CheckboxesListButton from './CheckboxesListButton'
import CustomButton from './CustomButton'
import CustomInput from './CustomInput'
import ResultsListSearch from './resultsListSearch'
import { buildURI, visit } from './utils'

import './mobileSearchBar.scss'
import './SearchBarInput.scss'

const MobileSearchBar = (props) => {
  const [theme, setTheme] = useState([])
  const [cities, setCities] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [search, setSearch] = useState('')

  const [toggleCity, setToggleCity] = useState(false)
  const [toggleTheme, setToggleTheme] = useState(false)
  const [toggleDate, setToggleDate] = useState(false)

  const handleToggleCity = () => setToggleCity(!toggleCity)
  const handleToggleTheme = () => setToggleTheme(!toggleTheme)
  const handleToggleDate = () => setToggleDate(!toggleDate)

  const handleChange = (event) => setSearch(event.target.value)

  const setDates = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const enterKeyPressed = (event) => event.charCode === 13 && handleSearch()

  const handleSearch = () => {
    const params = {}
    if (search) {
      params.search = search
    }
    if (cities) {
      params.city = cities
    }
    if (theme.length > 0) {
      params.show_type = theme
    }
    if (startDate) {
      params.from_date = (startDate ? startDate : endDate).format('DD-MM-YYYY')
      params.to_date = (endDate ? endDate : startDate).format('DD-MM-YYYY')
    }

    visit(buildURI('/evenements', params))
  }

  const handleReset = () => {
    setSearch('')
    setCities([])
    setTheme([])
    setDates(null, null)
  }

  return (
    <>
      <div className="mobile-search-bar-container">
        <div className="mobile-search-bar-global-section">
          <div className="mobile-search-head-section">
            <p>Rechercher</p>
            <CustomButton text="Fermer" iconRight="&#xea57;" style="underline" onClick={props.clickHandler} />
          </div>
          <div>
            <label className="mobile-search-title">Évènements, lieux culturels...</label>
            <div className="search-input-default">
              <span className="special-font">&#xead6; </span>
              <input placeholder="Quoi ?" value={search} onChange={handleChange} onKeyPress={enterKeyPressed}></input>
            </div>
          </div>
          <div className="mobile-search-results-list">
            {search.length >= 3
              && <ResultsListSearch search={search} />
            }
          </div>
          <div className="separator-mobile-search"></div>
          <div>
            <div className={toggleCity ? 'mobil-search-toggle' : 'mobile-search-hidden'}>
              <div className="mobile-search-section" onClick={handleToggleCity}>
                <p>Localisation</p>
                <span className="special-font toggle-section-icon">&#xec06;</span>
              </div>
              <div className="mobile-search-toggle-element">
                <CheckboxesListButton addable={true} possibilityCheckAll={true} title="Villes" placeholder="Où ?" icon="&#xea12;"
                                      dataList={props.cities} onChange={(value) => setCities(value)} />
              </div>
            </div>
            <div className="separator-mobile-search"></div>
            <div className={toggleTheme ? 'mobil-search-toggle' : 'mobile-search-hidden'}>
              <div className="mobile-search-section" onClick={handleToggleTheme}>
                <p>Thématiques</p>
                <span className="special-font toggle-section-icon">&#xec06;</span>
              </div>
              <div className="mobile-search-toggle-element">
                <CheckboxesListButton title="Thématiques" placeholder="Théâtres, musées, concerts..." icon="&#xeb65;"
                                      dataList={props.categories} onChange={(value) => setTheme(value)} />
              </div>
            </div>
            <div className="separator-mobile-search"></div>
            <div className={toggleDate ? 'mobil-search-toggle' : 'mobile-search-hidden'}>
              <div className="mobile-search-section" onClick={handleToggleDate}>
                <p>Dates</p>
                <span className="special-font toggle-section-icon">&#xec06;</span>
              </div>
              <div className="mobile-search-toggle-element">
                <CustomInput direction="up" title="Date" icon="&#xec8f;" type="date" placeholder="Quand ?"
                             onChange={(startDate, endDate) => setDates(startDate, endDate)} />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-search-cta">
          {/*<CustomButton border='classic' text="Effacer" color="white" clickHandler={handleReset} />*/}
          <CustomButton text="Rechercher" color="black" iconLeft="&#xead6;" onClick={handleSearch} />
        </div>
      </div>
    </>
  )
}

export default MobileSearchBar
