import React from 'react'
import CustomInputDate from './CustomInputDate';
import CustomInputText from './CustomInputText';

const CustomInput = (props) => {
  const Input = props.type === 'date' ? CustomInputDate : CustomInputText

  return <Input {...props} />
}

export default CustomInput
