import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import CustomPane from './CustomPane'
import { uuidV4 } from './utils'
import { transliterate } from 'transliteration'

import './CustomInputText.scss'

const CustomInputText = ({
                           addSearchTermOption = false, autoCompleteOptions, autoCompleteUrl, icon, initialValue = null, label, minLength = 4,
                           noResultsMessage, onChange, onData, onPress, onSelect, onSearchTermOption, placeHolder, reset, style, type, viewAllMessage, viewAllUrl
                         }) => {
  const [containerId, setContainerId] = useState(null)
  const [value, setValue] = useState(initialValue)
  const [searchTerm, setSearchTerm] = useState(null)
  const [showedPane, setShowedPane] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState({})
  const inputRef = useRef(null)
  const classNames = ['--citc-custom-input']
  let overflowY = 30

  switch (style) {
    case 'default-input':
    case 'default-color':
      overflowY = 40
      classNames.push(style)
      break
  }

  const autoCompleteEnabled = !!autoCompleteUrl || !!autoCompleteOptions

  const doOpen = () => setShowedPane(Object.keys(options).length !== 0)
  const doClickOutsidePane = (event) => !event.target.closest(`#${containerId}`) && showedPane && setShowedPane(false)
  
  const doFocus = () => {
    if (inputRef.current === null) return

    inputRef.current.focus()
    doOpen()
  }

  const doChange = (event) => {
    const isEmpty = event.target.value.length === 0
    const newValue = isEmpty ? null : event.target.value

    setValue(newValue)
    setSearchTerm(newValue)
  }

  const doSelect = (results) => {
    if (results.length !== 1) return

    const option = options[results[0]]

    setShowedPane(false)
    setSearchTerm(null)

    if (!option) return

    setValue(option.label)

    if (!!onSelect) onSelect(option)
  }

  const promiseOptions = (promise) => {
    promise
      .then(res => {
        if ((typeof onData) === 'undefined') {
          console.warn(`Ondata function is not defined on this input. (${containerId} : ${label})`)
          return res
        }

        return onData(res)
      })
      .then(res => {
        if (!addSearchTermOption) return res

        const searchTermOptionId = `search-term-${uuidV4()}`

        if (onSearchTermOption) {
          res[searchTermOptionId] = onSearchTermOption(searchTerm)
        } else {
          res[searchTermOptionId] = { label: searchTerm }
        }

        return res
      })
      .then(setOptions)
      .then(() => setLoading(false))
  }

  useEffect(() => {
    window.addEventListener('click', doClickOutsidePane)
    return () => window.removeEventListener('click', doClickOutsidePane)
  }, [showedPane])

  useEffect(() => {
    setContainerId(`custom-input-${uuidV4()}`)
  }, [])

  useEffect(() => {
    if ((typeof onChange) === 'undefined') {
      console.warn(`Onchange function is not defined on this input. (${containerId} : ${label})`)
      return
    }

    onChange(value)
  }, [value])

  useEffect(() => {
    if (!!searchTerm && searchTerm.length >= minLength) return

    setOptions({})
    setSearchTerm(null)
  }, [searchTerm])

  useEffect(() => {
    const showed = Object.keys(options).length !== 0 || loading

    setShowedPane(showed)
  }, [options, loading])

  useEffect(() => {
    if (!autoCompleteEnabled || !autoCompleteUrl) return
    if (!searchTerm || searchTerm.length < minLength) return

    setLoading(true)
    promiseOptions(
      fetch(autoCompleteUrl(searchTerm))
        .then(res => res.json())
    )
  }, [searchTerm])

  useEffect(() => {
    if (!autoCompleteEnabled || !autoCompleteOptions) return
    if (!searchTerm || searchTerm.length <= minLength) return

    setLoading(true)
    promiseOptions(
      new Promise((resolve, _reject) => {
        resolve(Object.entries(autoCompleteOptions).filter(([_key, el]) => {
          return transliterate(el.label.toLowerCase()).search(transliterate(searchTerm.trim().toLowerCase())) !== -1
        }).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}))
      })
    )
  }, [searchTerm])

  useEffect(() => {
    setValue(initialValue)
  }, [])

  useEffect(() => {
    if(!reset) return
    setValue('')
    setSearchTerm('')
  },[reset])

  return (
    <div id={containerId} className={classNames.join(' ')}>
      {icon && <span className="icon">{icon}</span>}
      <fieldset>
        {label && <div className="label" onClick={doFocus}>{label}</div>}
        <div className="input">
          <input ref={inputRef} type={type || 'text'} autoComplete="off" onChange={doChange} onClick={doOpen} onKeyUp={onPress} placeholder={placeHolder}
                 value={value || undefined} />
          {autoCompleteEnabled &&
            <CustomPane loading={loading} noResultsMessage={noResultsMessage} onChange={doSelect} options={options} overflowY={overflowY}
                        showed={showedPane} viewAllUrl={viewAllUrl && viewAllUrl(searchTerm)} viewAllMessage={viewAllMessage} />}
        </div>
      </fieldset>
    </div>
  )
}

CustomInputText.propTypes = {
  addSearchTermOption: PropTypes.bool,
  autoCompleteUrl: PropTypes.func,
  autoCompleteOptions: PropTypes.array,
  icon: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  minLength: PropTypes.number,
  noResultsMessage: PropTypes.string,
  onChange: PropTypes.func,
  onData: PropTypes.func,
  onPress: PropTypes.func,
  onSelect: PropTypes.func,
  onSearchTermOption: PropTypes.func,
  placeHolder: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.string,
  viewAllMessage: PropTypes.string,
  viewAllUrl: PropTypes.func
}

export default CustomInputText
