import React from 'react'
import ThemeCard from './themeCard'
import { visit } from './utils'

import theatreImg from '../../assets/images/themeCards/theatre&show.jpg'
import concertImg from '../../assets/images/static_pages/brand/concert.jpg'
import museumImg from '../../assets/images/static_pages/brand/museum.jpg'
import multiImg from '../../assets/images/static_pages/brand/multi.jpg'

import './ourOffer.scss'
import CustomButton from './CustomButton'

const OurOffer = () => {
  return (
    <div className="--citc-our-offer">
      <h1>NOTRE OFFRE</h1>
      <div className="theme-cards-container">
        <div className="theme-cards-list">
          <ThemeCard
            title="Théâtre & Spectacles"
            image={theatreImg}
            color="red"
            info1="1200 spectacles / mois"
            info2="450 salles en France"
            slug="coffret-spectacle"
          />
          <ThemeCard
            title="Concerts"
            image={concertImg}
            color="blue"
            info1="600 concerts / mois"
            info2="250 salles en France"
            slug="coffret-concert"
          />
          <ThemeCard
            title="Musées & Monuments"
            image={museumImg}
            color="green"
            info1="500 expositions et visites / mois"
            info2="250 institutions en France"
            slug="coffret-musees-monuments"
          />
          <ThemeCard
            title="Multi-thématiques"
            image={multiImg}
            color="yellow"
            info1="2200 événements / mois"
            info2="1000 lieux culturels en France"
            slug="coffret-multiculturels"
          />
        </div>
      </div>
      <div className="section">
        <p>Offrir un coffret cadeau Cultur'In The City, <span>c'est offrir à son bénéficiaire la chance de partager des
          moments inoubliables avec ses proches, en toute flexibilité </span>! Une soirée au théâtre, un One-Man Show,
          une exposition, un concert : laissez-lui la possibilité de faire
          une sortie hors des sentiers battus qui lui laisseront des souvenirs plein la tête&nbsp;!</p>
        <p>Cultur'In The City, c'est aussi un moyen de <span>se faire plaisir avec des découvertes culturelles
          inattendues</span> pour changer de ses habitudes, notamment grâce aux recommandations de l'équipe&nbsp;!</p>
        <p>Faites votre choix parmi <span> + de 100 coffrets cadeaux</span> sur notre site au sein de nos 3 gammes principales :
          Théâtre, Concerts et Musées, et découvrez de multiples déclinaisons (Humour & One Man Show, Spectacles en famille,
          Têtes d’affiche à Paris, Concerts de Jazz, Châteaux de France...)</p>
        <p>En fonction de ses envies, le bénéficiaire sélectionne ensuite un événement parmi nos <span>2000 sorties spectacles,
          concerts et musées chaque mois</span> chez l’un de nos <span>1000 partenaires partout en France</span>, des plus prestigieux aux plus intimistes.</p>
        <p>Il découvrira ainsi de nouveaux hauts lieux de la culture, aussi bien à Paris (Bobino, le Théâtre du Point-Virgule, La Scala,
          Musée d’Orsay...) que dans toute la France (MuCEM de Marseille, Cité du Vin à Bordeaux, Musée de la Piscine à
          Roubaix, Opéra National de Lyon, Théâtre des 100 Noms à Nantes, et bien d’autres encore ..)&nbsp;!</p>
        <div>
          <p>Consultez la liste de tous nos partenaires</p>
          <CustomButton text=" ici." style="underline" onClick={() => visit('/lieux-partenaires')} />
        </div>
      </div>
    </div>
  )
}

export default OurOffer
