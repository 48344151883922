import { setDate } from 'date-fns'
import React, { useState, useEffect } from 'react'
import './singleDateInput.scss'

const SingleDateInput = () => {
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [rangeYear, setRangeYear] = useState([])
    const [rangeDay, setRangeDay] = useState([])

    const handleChangeDay = (event) => {
        setDay(event.target.value)
    }

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        const newyear = new Date().getFullYear()
        const listY = []
        const listD = []
        for (var i = newyear; i >= newyear - 100; i--) [
            listY.push(i)
        ]
        setRangeYear(listY)
        for (var i = 1; i <= 31; i++) {
            listD.push(i)
        }
        setRangeDay(listD)
    }, [])

    return (
        <>
            <div className='single-date-input'>
                <div className="select-bdate">
                    <select className="select-bday" onChange={handleChangeDay}>
                        <option value="jour" disabled selected>jour</option>
                        {rangeDay.map(key => (
                            <option value={key}>{key}</option>
                        ))}
                    </select>
                    <p className="separator-select-bdate">-</p>
                    <select className="select-bmonth" onChange={handleChangeMonth}>
                        <option value="mois" disabled selected>mois</option>
                        <option value="01">Janvier</option>
                        <option value="02">Fevrier</option>
                        <option value="03">Mars</option>
                        <option value="04">Avril</option>
                        <option value="05">Mai</option>
                        <option value="06">Juin</option>
                        <option value="07">Juillet</option>
                        <option value="08">Aout</option>
                        <option value="09">Septembre</option>
                        <option value="10">Octobre</option>
                        <option value="11">Novembre</option>
                        <option value="12">Décembre</option>
                    </select>
                    <p className="separator-select-bdate">-</p>
                    <select className="select-byear" onChange={handleChangeYear}>
                        <option value="année" disabled selected>année</option>
                        {rangeYear.map(key => (
                            <option value={key}>{key}</option>
                        ))}
                    </select>
                </div>

            </div>

            <input className="hidden-true-value" value={`${day}-${month}-${year}`} />
        </>
    )
}

export default SingleDateInput;
