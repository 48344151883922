import React, { useEffect, useState } from 'react'
import { visit } from './utils'

import './resultsListSearch.scss'

const ResultsListSearch = ({ search }) => {
  const [results, setResults] = useState([])

  useEffect(() => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    fetch(
      `${baseUrl}/api/search/shows?q=${search}`)
      .then(res => res.json())
      .then(setResults)
  }, [search])

  return (
    <div className="results-list-search">
      <ul>
        {results.map((data) => (
          <li className="result-search">
            <p onClick={() => visit(data.public_url)}>{data.name}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ResultsListSearch
