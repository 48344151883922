import React from 'react'
import CustomButton from './CustomButton'
import { visit } from './utils'

import './howItWorks.scss'

const HowItWorks = () => {
  return (
    <div className="--citc-how-it-works">
      <h1>COMMENT ÇA MARCHE ?</h1>
      <iframe src="https://www.youtube-nocookie.com/embed/7GbePdaRbcw" />
      <div className="section">
        <p>Une fois le coffret activé par son bénéficiaire, rien de plus simple : <span>la réservation des billets
          se fait directement sur notre site</span> via son compte.</p>
        <p>Tous les coffrets sont valables <span>3 ans et 3 mois</span> à partir de la date d’achat,
          de quoi vous laisser le temps de prévoir vos sorties en toute sérénité&nbsp;!</p>
        <div>
          <p>D’autres questions ?</p>
          <div>
            <p>N’hésitez pas à vous référer à notre</p>
            <CustomButton text=" FAQ." style="underline" onClick={() => visit('/faq')} />
          </div>
        </div>
        <p>Foncez et bonnes découvertes culturelles&nbsp;!</p>
      </div>
    </div>
  )
}

export default HowItWorks
