import React from 'react'
import PropTypes from 'prop-types'

import './Banner.scss'

const Banner = ({ content, shortContent, link }) => {
  const containerClassNames = ['--citc-banner']

  if (link) containerClassNames.push('with-link')

  return (
    <div className={containerClassNames.join(' ')}>
      <div className="content">
        <p className="long">{content}</p>
        <p className="short">{shortContent}</p>
        {link && <a href={link}>En savoir plus <span aria-hidden="true">&rarr;</span></a>}
      </div>
    </div>
  )
}

Banner.propTypes = {
  content: PropTypes.string,
  shortContent: PropTypes.string,
  link: PropTypes.string,
}

export default Banner;
