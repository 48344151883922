import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CustomSelect from './CustomSelect'
import CustomInput from './CustomInput'
import CustomButton from './CustomButton'
import MobileSearchBar from './mobileSearchBar'
import { visit } from './utils'

import './SearchBar.scss'

const SearchBar = ({ showTypes, cities, topCities }) => {
  const [visibilityDesktopSearchbar, setVisibilityDesktopSearchbar] = useState(true)
  const [visibilityMobileSearchbar, setVisibilityMobileSearchbar] = useState(false)
  const [previousOffset, setPreviousOffset] = useState(0)
  const [searchTerm, setSearchTerm] = useState(null)
  const [city, setCity] = useState(null)
  const [showType, setShowType] = useState(null)
  const visibilitySearchbar = visibilityDesktopSearchbar && !visibilityMobileSearchbar

  const controlSearchbar = () => {
    const currentOffset = Math.round(window.scrollY)
    setVisibilityDesktopSearchbar(currentOffset < 50 || previousOffset > currentOffset)
    setPreviousOffset(currentOffset)
  }

  const doToggleMobileSearchBar = () => {
    setVisibilityMobileSearchbar(!visibilityMobileSearchbar)
    setVisibilityDesktopSearchbar(!visibilityDesktopSearchbar)
  }

  const doSearch = () => {
    const url = new URL(`${window.location.protocol}//${window.location.host}/evenements`)
    if (searchTerm) url.searchParams.set('search', searchTerm)
    if (city) url.searchParams.append('cities[]', city)
    if (showType) url.searchParams.append('show_types[]', showType)

    visit(url)
  }

  useEffect(() => {
    window.addEventListener('scroll', controlSearchbar)
    return () => window.removeEventListener('scroll', controlSearchbar)
  }, [previousOffset])


  useEffect(() => {
    if (showType === null && city === null) return

    doSearch()
  }, [showType, city])

  const baseUrl = `${window.location.protocol}//${window.location.host}`

  return (
    <>
      <div className={`search-bar ${visibilitySearchbar ? 'show' : 'hide'}`}>
        <div className={`mobile ${visibilitySearchbar ? 'show' : 'hide'}`}>
          <CustomButton style="none" iconLeft="&#xead6;" text="Rechercher" onClick={doToggleMobileSearchBar} />
        </div>
        <div className={`desktop ${visibilitySearchbar ? 'show' : 'hide'}`}>
          <div className="inputs">
            <CustomInput label="Évènement" placeHolder="Quoi ?" onPress={(event) => event.keyCode === 13 && doSearch()}
                          onChange={setSearchTerm} onSelect={(option) => visit(option.extra.url)}
                          autoCompleteUrl={(query) => `${baseUrl}/api/search/shows?q=${query}`}
                          onData={(res) => res.reduce((previous, current) => {
                            let venueInfos = ''

                            if (current.venue) {
                              if (current.venue.city) venueInfos += current.venue.city
                              if (current.venue.zipcode) venueInfos += ` (${current.venue.zipcode})`
                            }

                            previous[current.id] = { label: current.name, subLabel: venueInfos, extra: { url: current.public_url } }
                            return previous
                          }, {})} viewAllUrl={(query) => `${baseUrl}/evenements?search=${query}`} />
            <span className="separator"></span>
            <CustomSelect label="Ville" placeHolder="Où ?" options={cities} onChange={setCity} searchable={true}
              noResultsMessage="Aucun évènement dans cette ville" />
            <span className="separator"></span>
            <CustomSelect label="Thématique" placeHolder="Théâtre, musée, concert..." options={showTypes} onChange={setShowType} />
          </div>
        </div>
      </div>
      {visibilityMobileSearchbar
        && <MobileSearchBar categories={showTypes} cities={topCities} clickHandler={doToggleMobileSearchBar} />
      }
    </>
  )
}

SearchBar.propTypes = {
  showTypes: PropTypes.object,
  cities: PropTypes.object,
  topCities: PropTypes.object
}

export default SearchBar
