import React from 'react'

import './reinsurance.scss'

const Reinsurance = () => {
  return (
    <>
      <div className='reinsurance-container'>
        <div className='reinsurance-section'>
          <span className='special-font'>&#xe992;</span>
          <h2 className='reinsurance-desktop-text'>Pour toutes les occasions</h2>
          <p className='reinsurance-desktop-text'>
            Coffrets et e-coffrets à
            <span> partir de 29€</span>
            .
            <br />
            Livraison
            <span> à domicile </span>
            ou
            <span> instantanée par e-mail</span>
            .
          </p>
          <p className='reinsurance-mobile-text'>Coffrets et e-coffrets à partir de 29€ </p>
        </div>
        <div className='reinsurance-section'>
          <span className='special-font'>&#xeb65;</span>
          <h2 className='reinsurance-desktop-text'>Offrez la liberté de choisir</h2>
          <p className='reinsurance-desktop-text'>
            Une offre de
            <span> 2200 spectacles, expositions et concerts </span>
            partout en France.
          </p>
          <p className='reinsurance-mobile-text'>Choix parmi 2200 sorties culturelles</p>
        </div>
        <div className='reinsurance-section'>
          <span className='special-font'>&#xec8f;</span>
          <h2 className='reinsurance-desktop-text'>Une utilisation ultra-simple</h2>
          <p className='reinsurance-desktop-text'>
            Validité de
            <span> 3 ans et 3 mois</span>
            .
            <br />
            Réservations
            <span> 100% sur notre site</span>
            .
          </p>
          <p className='reinsurance-mobile-text'>Validité de 3 ans et 3 mois</p>
        </div>
      </div>
    </>
  )
}

export default Reinsurance;
