import React, { useState } from 'react';
import CustomMenu from './customMenu';
import HeaderTile from './headerTile';

import './headerTabs.scss'

const HeaderTabs = ({ tab, active }) => {
  const [isVisible, setIsVisible] = useState(false)
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  const tabUrl = baseUrl + tab.path
  const hasCustomMenu = typeof tab.content !== 'undefined' && tab.content.length > 0
  const linkClassName = []
  const cssStyle = {}

  if (active) linkClassName.push('active-path')
  if (tab.bold) linkClassName.push('bold')

  if (tab.color?.charAt(0) === "#") {
    linkClassName.push('custom-color')
    cssStyle['--override-color'] = tab.color
  } else {
    switch (tab.color) {
      case 'red':
        linkClassName.push(tab.color)
        break
    }
  }

  const customMenuClassName = ['menu']
  if (!isVisible) customMenuClassName.push('hidden')

  return (
    <div className="--citc-header-tabs desktop" onMouseLeave={() => setIsVisible(false)}>
      <a className={linkClassName.join(' ')} style={cssStyle} onMouseEnter={() => setIsVisible(true)} href={tabUrl} data-turbolinks="false">
        {tab.prefix}{tab.title}{tab.suffix}
        {hasCustomMenu && <span className="icon dropdown-menu">&#xec03;</span>}
      </a>
      {hasCustomMenu &&
        <div className={customMenuClassName.join(' ')}>
          {tab.content.map((content, key) => (
            <div key={key} className={'content-' + content.type}>
              <h1>{content.title}</h1>
              {content.type === 'list' && <CustomMenu data={content.content} />}
              {content.type === 'tile' && <HeaderTile data={content.content} />}
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default HeaderTabs;
