import React, { useEffect, useRef } from "react";
import mapboxgl from '!mapbox-gl'

import './whereFindUs.scss'

const WhereFindUs = ({ brands, publicKey, stores }) => {

  mapboxgl.accessToken = publicKey
  const mapContainer = useRef(null);
  const transformedData = {
    features: stores.map(item => ({
      type: 'Feature',
      properties: {
        title: item.name,
        description: '',
      },
      geometry: {
        coordinates: [item.longitude, item.latitude],
        type: 'Point',
      },
    })),
    type: 'FeatureCollection',
  }

  const popup = (title) =>  new mapboxgl.Popup({ offset: 25 }).setText(title)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [2.333333, 48.866667],
      zoom: 5.5
    })
    transformedData.features.map((feature) =>
      new mapboxgl.Marker()
        .setLngLat(feature.geometry.coordinates)
        .setPopup(popup(feature.properties.title))
        .addTo(map)
    )
    map.addControl(new mapboxgl.NavigationControl(), "top-right");
    return () => map.remove();
  })

  return (
    <div className="--citc-where-find-us">
      <h1>OÙ NOUS TROUVER ?</h1>

      <div className="section">
        <p>Sur <span>notre site internet</span> et dans <span>800 points de vente physiques</span> partout en France
          (Cultura, Le Furet du Nord, Auchan, Carrefour, Leclerc, Monoprix...).</p>
        <p>Consultez la carte des points de vente</p>
      </div>
      <div ref={mapContainer} className="map-container" />
      <div className="brands">
        <h1>Nos enseignes partenaires en France</h1>
        <div>
          {brands.map((content, key) => (
            <img key={key} src={content.logo_url} alt={content.name} />
          ))}
        </div>
      </div>


    </div>
  )
}

export default WhereFindUs
