import React from 'react'
import { visit } from './utils'

import './brandImage.scss'

import team from '../../assets/images/static_pages/brand/team_with_product.jpg'
import teamTheatre from '../../assets/images/static_pages/brand/team_theatre.jpg'
import brandProduct from '../../assets/images/static_pages/brand/brand-product.jpg'
import collumn from '../../assets/images/static_pages/brand/collumn.jpg'
import product from '../../assets/images/static_pages/brand/product.jpg'


const BrandImage = () => {
  return (
    <div className="--citc-brand-image">
      <img src={team} />

      <div className="section">
        <img src={teamTheatre} />
        <div>
          <h1>QUI SOMMES-NOUS ?</h1>
          <a href="qui-sommes-nous" onClick={() => visit('/qui-sommes-nous')}>Rencontrez notre équipe de<br />passionné(e)s de culture&nbsp;!</a>
        </div>
      </div>

      <div className="section">
        <div>
          <h1>NOTRE OFFRE</h1>
          <a href="notre-offre" onClick={() => visit('/notre-offre')}>Profitez de nos 2000 sorties spectacles, concerts et musées chaque mois</a>
        </div>
        <img src={brandProduct} />
      </div>

      <div className="section">
        <img src={collumn} />
        <div>
          <h1>OÙ NOUS TROUVER ?</h1>
          <a href="ou-nous-trouver" onClick={() => visit('/ou-nous-trouver')}>Sur internet et en magasin <br />partout en France&nbsp;!</a>
        </div>
      </div>

      <div className="section">
        <div>
          <h1>COMMENT ÇA MARCHE ?</h1>
          <a href="comment-ca-marche" onClick={() => visit('/comment-ca-marche')}>On vous explique tout ici&nbsp;!</a>
        </div>
        <img src={product} />
      </div>
    </div>
  )
}

export default BrandImage
