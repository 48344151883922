import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import DraggableSliderTab from './draggableSliderTab'
import ShowsResults from './showsResults'
import Filters from './filters'
import Loader from './Loader'
import { baseUrl, pluralize } from './utils'

import './shows.scss'
import CustomButton from './CustomButton'
import Api from './api'

const Shows = ( allCities ) => {
  const [mounted, setMounted] = useState(false)
  const [state, setState] = useState(null)
  const [results, setResults] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [maxPage, setMaxPage] = useState(null)
  const [maxResults, setMaxResults] = useState(null)
  const [showedTabs, setShowedTabs] = useState([])
  const [toggleMobileFilter, setToggleMobileFilter] = useState(false)
  const [currentTab, setCurrentTab] = useState(null)
  const [currentParams, setCurrentParams] = useState({})
  const [currentPreset, setCurrentPreset] = useState({})
  const [reset, setReset] = useState(false)

  const TABS = [
    { key: 'results', name: 'Résultats' },
    { key: 'spotlight', name: 'À la une' },
    { key: 'news', name: 'Nouveautés' },
    { key: 'see_all', name: 'Voir tout' },
    { key: 'teams_recommendations', name: 'Les recos de l\'équipe' },
    { key: 'public_loves', name: 'Le public adore' }
  ]
  const mobileFiltersClassNames = ['left']

  if (!toggleMobileFilter) {
    mobileFiltersClassNames.push('inactive')
    document.body.classList.remove('unscrollable')
  }

  if (toggleMobileFilter) document.body.classList.add('unscrollable')

  const getPage = () => {
    if (state !== 'initial') setState('loading')

    Api.shows
      .index({ ...currentParams, ...currentPreset, page: currentPage + 1 })
      .then((response) => {
        const newResults = response.data.filter(item => !results.some(resultItem => resultItem.slug === item.slug))
        setResults([...results, ...newResults])
        setMaxResults(response.meta.total)
        setMaxPage(response.meta.max_pages)
        setCurrentPage(currentPage + 1)
        setState('ready')
      })
      .catch((error) => {
        setState('ready')
      })
  }

  const resetStates = () => {
    setState('initial')
    setCurrentPage(0)
    setMaxPage(null)
    setMaxResults(null)
    setResults([])
  }

  useEffect(() => {
    if (!reset) return
    setReset(false)
    setToggleMobileFilter(false)
  }, [reset])

  useEffect(() => {
    if (!mounted) return
    if (state === 'initial' || state === 'request') getPage()
  }, [state])

  useEffect(() => {
    if (!mounted) return

    resetStates()

    switch (currentTab) {
      case 'spotlight':
        setCurrentPreset({ spotlight: true })
        break

      case 'news':
        setCurrentPreset({ news: true })
        break

      case 'teams_recommendations':
        setCurrentPreset({ teams_recommendations: true })
        break

      case 'public_loves':
        setCurrentPreset({ public_loves: true })
        break

      default:
        setCurrentPreset({})
    }
  }, [currentTab])

  useEffect(() => {
    if (!mounted) return

    const hasParams = Object.keys(currentParams).length > 0

    if (hasParams) {
      setShowedTabs(TABS.filter(tabs => tabs.key !== 'see_all'))
      currentTab === 'results' ? resetStates() : setCurrentTab('results')
    } else {
      setShowedTabs(TABS.filter(tabs => tabs.key !== 'results'))
      setCurrentTab('spotlight')
    }
  }, [currentParams])

  useEffect(() => {
    Sentry.captureMessage('Shows mounted');
    setMounted(true)
  }, [])

  return (
    <div className="--citc-shows">
      <div className={mobileFiltersClassNames.join(' ')}>
        <Filters allCities={allCities.allCities} handleClickClose={() => setToggleMobileFilter(!toggleMobileFilter)} onParamsChange={(params) => setCurrentParams({ ...params })} onReset={setReset} reset={reset} />
      </div>
      <div className="right">
        <div className="header">
          <h1>Évènements</h1>
          <CustomButton text="Filtrer" iconRight="&#xeb09;" style="underline" size="xl" onClick={() => setToggleMobileFilter(!toggleMobileFilter)} />
        </div>
        {maxResults !== null && <p>{`${maxResults} ${pluralize(maxResults, 'résultat trouvé', 'résultats trouvés')}`}</p>}
        <DraggableSliderTab tabsList={showedTabs} onChange={setCurrentTab} initialCurrent={currentTab} />
        {state !== 'initial' && <ShowsResults results={results} resetFilter={() => setReset(true)} />}
        {state === 'loading' && <Loader />}
        {(state === 'initial' || state === null) && <Loader />}
        {state === 'ready' && currentPage < maxPage &&
          <CustomButton text={`Voir plus ${results.length}/${maxResults}`} style="broke-corner" color="primary" size="xl"
            onClick={() => setState('request')} />}
      </div>
    </div>
  )
}

export default Shows
