import React from 'react'
import PropTypes from 'prop-types'

import './CustomButton.scss'

const CustomButton = ({
                        color, boxShadow, fit, iconLeft, iconRight, onClick, overwriteBackgroundColor,
                        overwriteColor, size, style, tagName, text, type, data
                      }) => {
  const classNames = ['--citc-custom-button']
  let CustomTag = 'a'
  let customType = type
  const cssStyle = {}

  switch (tagName) {
    case 'button':
      CustomTag = tagName
      break
  }

  if (tagName === 'button') customType ||= 'submit'

  if (fit) classNames.push('fit')

  switch (style) {
    case 'broke-corner':
    case 'no-background':
    case 'underline':
      classNames.push(style)
      break

    case 'none':
      break

    default:
      classNames.push('default')
  }

  switch (size) {
    case 'sm':
    case 'm':
    case 'xl':
    case 'custom-calendar':
      classNames.push(size)
      break
  }

  if (overwriteBackgroundColor || overwriteColor) {
    classNames.push('custom-color')

    if (overwriteBackgroundColor) cssStyle['--override-background-color'] = overwriteBackgroundColor
    if (overwriteColor) cssStyle['--override-color'] = overwriteColor
  } else {
    switch (color) {
      case 'black':
      case 'red':
      case 'secondary':
      case 'white':
      case 'danger':
      case 'pink':
        classNames.push(color)
        break

      default:
        classNames.push('primary')
    }
  }

  switch (boxShadow) {
    case 'black-shadow':
      classNames.push(boxShadow)
      break
  }

  return (
    <CustomTag type={customType} className={classNames.join(' ')} onClick={onClick} style={cssStyle} {...data}>
      {iconLeft && <span className="left-icon">{iconLeft}</span>}
      {text && <span className="text">{text}</span>}
      {iconRight && <span className="right-icon">{iconRight}</span>}
    </CustomTag>
  )
}

CustomButton.propTypes = {
  color: PropTypes.string,
  boxShadow: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.string,
  text: PropTypes.string,
  overwriteColor: PropTypes.string,
  overwriteBackgroundColor: PropTypes.string,
  data: PropTypes.object
}

export default CustomButton

