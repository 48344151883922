import React from 'react'

import './Loader.scss'

const Loader = () => {

  return (
    <div className='--citc-loader'></div>
  )
}

export default Loader
