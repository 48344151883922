import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { transliterate } from 'transliteration'

import './CustomPane.scss'

const CustomSimplePane = ({ children, overflowUnit, overflowX, overflowY, maxWidth, showed, style }) => {
  overflowUnit ||= 'px'

  const paneClassNames = ['pane']
  const top = overflowY > 0 ? `${overflowY}${overflowUnit}` : null
  const bottom = overflowY < 0 ? `${overflowY}${overflowUnit}` : null
  const left = overflowX > 0 ? `${overflowX}${overflowUnit}` : null
  const right = overflowX < 0 ? `${overflowX}${overflowUnit}` : null

  if (maxWidth) maxWidth = `${maxWidth}px`

  paneClassNames.push(showed ? 'show' : 'hide')

  switch (style) {
    case 'danger':
    case 'success':
    case 'warning':
      paneClassNames.push(style)
      break
  }

  return (
    <div className="--citc-custom-pane"
         style={{ '--offset-top': top, '--offset-right': right, '--offset-bottom': bottom, '--offset-left': left, '--max-width': maxWidth }}>
      <div className={paneClassNames.join(' ')}>
        {children}
      </div>
    </div>
  )
}

CustomSimplePane.propTypes = {
  overflowUnit: PropTypes.string,
  overflowX: PropTypes.number,
  overflowY: PropTypes.number,
  showed: PropTypes.bool
}

const CustomErrorPane = ({ messages, overflowUnit, overflowX, overflowY, style }) => {
  style ||= 'error'

  if (!Array.isArray(messages)) messages = [messages]

  return (
    <CustomSimplePane style={style} showed={true} overflowUnit={overflowUnit} overflowX={overflowX} overflowY={overflowY}>
      <ul className="errors">
        {messages.map(message => <li className="message">{message}</li>)}
      </ul>
    </CustomSimplePane>
  )
}

CustomErrorPane.propTypes = {
  errorMessage: PropTypes.string,
  overflowUnit: PropTypes.string,
  overflowX: PropTypes.number,
  overflowY: PropTypes.number
}

const CustomPane = ({
                      checkable, loading, multiple, noResultsMessage, onChange, options, overflowUnit, overflowX, overflowY,
                      searchable, showed, viewAllMessage, viewAllUrl
                    }) => {
  const [value, setValue] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const inputRef = useRef(null)

  const results = Object.entries(options).filter(([_key, el]) => {
    return searchTerm.length !== 0 ? transliterate(el.label.toLowerCase()).search(transliterate(searchTerm.trim().toLowerCase())) !== -1 : true
  })
  const showedViewAll = viewAllUrl && !loading && results.length !== 0

  const doSelect = (key) => {
    if (!multiple && !checkable) {
      setValue([key])
      return
    }

    if (value.includes(key)) {
      const index = value.indexOf(key)
      if (index !== -1) value.splice(index, 1)
    } else {
      value.push(key)
    }

    setValue([...value])
  }

  const doSearch = (event) => setSearchTerm(event.target.value)

  useEffect(() => {
    if ((typeof onChange) === 'undefined') {
      console.warn(`Onchange function is not defined on this pane.`)
      return
    }

    onChange(value)
  }, [value])

  useEffect(() => {
    if (!searchable) return
    if (inputRef.current === null) return

    showed ? inputRef.current.focus() : inputRef.current.blur()
  }, [showed])

  return (
    <CustomSimplePane showed={showed} overflowUnit={overflowUnit} overflowX={overflowX} overflowY={overflowY} maxWidth={270}>
      {searchable && (
        <fieldset className="search">
          <span className="icon" />
          <input ref={inputRef} autoComplete="off" type="search" onChange={doSearch} />
        </fieldset>
      )}
      <ul className="options">
        {loading ?
          (<li className="loading"><span className="ellipsis"><span /><span /><span /><span /></span></li>)
          :
          results.length !== 0 ?
            results.map(([key, el]) => (
              <li key={key} className="option" onClick={() => doSelect(el?.key || key)}>
                {checkable && (
                  <span className={`checkbox ${value.includes(el?.key || key) ? 'checked' : 'unchecked'}`} />
                )}
                <span className="label">{el.label}</span>
                {el.subLabel ? <span className="sub-label">{el.subLabel}</span> : null}
              </li>
            ))
            :
            (<li className="no-results">{noResultsMessage || 'Aucun résultat'}</li>)
        }
      </ul>
      {showedViewAll && <a href={viewAllUrl} className="view-all">{viewAllMessage || 'Voir tous les résultats'}</a>}
    </CustomSimplePane>
  )
}

CustomPane.propTypes = {
  checkable: PropTypes.bool,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.any,
  overflowUnit: PropTypes.string,
  overflowX: PropTypes.number,
  overflowY: PropTypes.number,
  searchable: PropTypes.bool,
  showed: PropTypes.bool,
  viewAllMessage: PropTypes.string,
  viewAllUrl: PropTypes.string
}

export default CustomPane
export { CustomErrorPane }
