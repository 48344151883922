import React from 'react'
import actorsImg from './actors-img'
import CustomButton from './CustomButton'
import { visit } from './utils'

import './actors.scss'

const Actors = () => {
  return (
    <div className="--citc-home-actors">
      <div className="content">
        <h1>+ de 1000 lieux culturels</h1>
        <p>Théâtres, musées & monuments, salles de concerts partout en France</p>
        <CustomButton text="Voir plus" iconRight="&#xec06;" style="underline" onClick={() => visit('/lieux-partenaires')} />
      </div>
      <div className="logos">
        {Object.entries(actorsImg).map(([key, img]) => <img key={key} className={key} src={img.src} alt={img.alt} width={img.width} />)}
      </div>
      <CustomButton text="Voir plus" iconRight="&#xec06;" style="underline" onClick={() => visit('/lieux-partenaires')} />
    </div>
  )
}

export default Actors
