import React, { useState } from 'react';
import './tooltip.scss';

const Tooltip = ({ text, children, minLength }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    if (children.props.children.length > minLength) setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      className={`tooltip ${isHovered ? 'active' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <span className="tooltip-text">{text}</span>
    </div>
  );
};

export default Tooltip;